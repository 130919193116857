/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('PaymentRefundCtrl', ['$scope', 'UserModel',
    function ($scope, UserModel) {
        var userid = $scope.$parent.user.id;

        $scope.errors = null;
        $scope.send = false;
        $scope.sending = false;
        $scope.actions = [
            {key: 'debit', value: 'Debit'},
            {key: 'credit', value: 'Credit'}
        ];
        $scope.action = 'debit';
        $scope.input = {
            amount: '',
            description: ''
        };

        $scope.submit = function($event){
            $event.preventDefault();

            $scope.errors = null;
            $scope.send = false;
            $scope.sending = true;

            if($scope.form.$invalid){
                return false;
            }

            var user = new UserModel({id: userid});
            user.amount = String($scope.input.amount);

            if($scope.input.description){
                user.description = $scope.input.description;
            }

            user['$' + $scope.action]({}, function(){
                $scope.send = true;
                $scope.sending = false;
                $scope.closePopover();
                $scope.$emit('user-list-reload');
                $scope.$emit('msg:admin', ["Payment/refund amount saved", 'alert-success']);
            }, function(response){
                $scope.send = true;
                $scope.sending = false;
                if (response.status === 400) {
                  $scope.errors = response.data;
                  return;
                }
                $scope.closePopover();
                $scope.$emit(
                  'msg:admin',
                  ["Can't set payment/refund amount, try again later", 'alert-danger']
                );
            });
        };

        $scope.$on('popover-opened', function(){
            $scope.input.amount = '';
            $scope.input.description = '';
        });
    }]);

