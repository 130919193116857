/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('CreditLimitCtrl', ['$scope', 'UserModel',
    function ($scope, UserModel) {

        var userid = $scope.$parent.user.id;

        $scope.submit = function($event){
            $scope.send = true;
            $event.preventDefault();

            if($scope.form.$invalid){
                return false;
            }

            var user = new UserModel({id: userid});
            user.billing_profile = {
                credit_limit: String($scope.amount)
            };

            $scope.$emit('msg:admin', ['Saving credit limit...', 'alert-info']);
            user.$update({}, function(){
                $scope.closePopover();
                $scope.$emit('user-list-reload');
                $scope.$emit('msg:admin', ["Credit limit updated", 'alert-success']);
            }, function(){
                $scope.closePopover();
                $scope.$emit('msg:admin', ["Can't set credit limit, try again later", 'alert-danger']);
            });
        };

        $scope.$on('popover-opened', function(){
            $scope.send = false;
            $scope.amount = Number($scope.$parent.user.billing_profile.credit_limit);
        });
    }]);

