/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('reportsBySalesRepCtrl', {
    controller: reportsBySalesRepCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/reports-by-sales-rep/reports-by-sales-rep.html').default
  });

reportsBySalesRepCtrl.$inject = ['apiPrefixUrl', 'userServ', 'time', '$scope', 'ExcelService'];
function reportsBySalesRepCtrl(apiPrefixUrl, userServ, time, $scope, ExcelService) {
  var vm = this;

  vm.userServ = userServ;
  vm.isLoading = false;
  vm.salesmen = setRepNames();
  vm.accountManagers = setRepNames();

  vm.firstDateRangeId = 'last_month';
  vm.secondDateRangeId = 'this_month';
  vm.firstStartDate = null;
  vm.firstEndDate = null;
  vm.secondStartDate = null;
  vm.secondEndDate = null;
  vm.firstBackendStartDate = '';
  vm.firstBackendEndDate = '';
  vm.secondBackendStartDate = '';
  vm.secondBackendEndDate = '';
  vm.columnsTheme = 'simple';

  vm.setDateByCalendar = function (key, startDate, endDate) {
    setDate(key, startDate, endDate);
    vm[key + 'DateRangeId'] = '';
  };

  vm.setDateByPicker = function (key, startDate, endDate) {
    setDate(key, startDate, endDate);
  };

  vm.filterSalesmen = function (repNames) {
    vm.salesmen = setRepNames(repNames);
    vm.accountManagers = setRepNames();
  };

  vm.filterAccountManagers = function (repNames) {
    vm.accountManagers = setRepNames(repNames);
    vm.salesmen = setRepNames();
  };

  vm.getExportToExcelURL = function () {
    var url = apiPrefixUrl + '/reports-by-sales-rep/excel';
    return url;
  };

  function setRepNames (repNames) {
    repNames = repNames || [];
    return {
      names: repNames,
      joinedNames: repNames.join(',')
    };
  }

  function setDate (key, startDate, endDate) {
    vm[key + 'StartDate'] = startDate;
    vm[key + 'EndDate'] = endDate;
    vm[key + 'BackendStartDate'] = startDate ? time.getBackendDate(startDate) : '';
    vm[key + 'BackendEndDate'] = endDate ? time.getBackendDate(endDate) : '';
  }

  $scope.onExportToExcel = function() {
    ExcelService.downloadSalesRepList(
        vm.accountManagers.joinedNames,
        vm.salesmen.joinedNames,
        vm.firstBackendStartDate,
        vm.firstBackendEndDate,
        vm.secondBackendStartDate,
        vm.secondBackendEndDate).then(
      function() {},
      function(error) {
        // TODO: show error
        console.log(error);
      }
    );
  };

}


