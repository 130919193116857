/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'reportsBySalesPicker', {
      controller: reportsBySalesRepPickerCtrl,
      template: require('$views/admin/reports-by-sales-rep/reports-by-sales-rep-picker.html').default,
      controllerAs: 'vm',
      bindings: {
        onChange: '&',
        source: '@',
        selectedNames: '<'
      }
    }
  );

reportsBySalesRepPickerCtrl.$inject = ['AdvertiserService', '$scope'];
function reportsBySalesRepPickerCtrl(AdvertiserService, $scope) {
  var vm = this;
  vm.repNames = [];
  vm.isLoading = false;
  vm.isError = false;

  vm.$onInit = function () {
    getReps();
  };

  vm.setRepName = function () {
    vm.onChange({repNames: vm.selectedNames});
  };

  function getReps () {
    vm.isError = false;
    vm.isLoading = true;
    vm.repNames = [];

    AdvertiserService.fetchSalesRepOptions().then(function (resp) {
      vm.isLoading = false;
      vm.repNames = Object.keys(resp[vm.source]);
      $scope.$apply();
    }, function (resp) {
      vm.isLoading = false;
      vm.isError = true;
    });
  }

}

