/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').controller('SetPriceCtrl', [
    '$scope', 'LineItemService',
    function($scope, LineItemService) {
        $scope.send = false;
        $scope.msgs = [];

        $scope.submit = function($event){
            $scope.send = true;
            $scope.msgs = [];
            $event.preventDefault();

            var line_item_id = $scope.form.line_item_id.$viewValue,
                revenue_value = $scope.form.revenue_value.$viewValue;

            if($scope.form.$invalid){
                $scope.msgs = [['Please check that all required fields are filled', 'alert-danger']];
                return false;
            };

            $scope.msgs = [['Updating...', 'alert-info']];

            LineItemService.setPrice(line_item_id, revenue_value).then(function(resp){
                $scope.closePopover();
                $scope.$emit('newbiling-list-reload', {"advertiser_id": $scope.report.advertiser.id});
            });

        };
    }
]);

