/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'missedCapReportTable', {
      controller: missedCapReportTableCtrl,
      template: require('$views/admin/missed-cap-report/table.html').default,
      controllerAs: 'vm',
      bindings: {
        columns: '<',
        filterUsername: '<',
        filterPercentDiff: '<',
        isLoading: '='
      }
    }
  );

missedCapReportTableCtrl.$inject = ['$http', 'apiPrefixUrl', 'NgTableParams', 'ReportTable'];
function missedCapReportTableCtrl($http, apiPrefixUrl, NgTableParams, ReportTable) {
  var vm = this;
  vm.errors = [];
  vm.isLoading = false;
  vm.filterUsername = '';
  vm.filterPercentDiff = null;
  vm.tableParams = new NgTableParams(
    {
      sorting: {'line_item_obj__value': 'asc'}
    },
    {
      getData: fetchReportData,
      counts: []
    }
  );
  vm.reportTable = ReportTable(vm.tableParams);
  vm.setOrder = vm.reportTable.setOrder;
  vm.$onChanges = vm.reportTable.onChanges;

  vm.isMissedOpportunityLow = function(recordObj) {
    var missedValue = recordObj['missed_opportunity'];
    var budget = recordObj['used_budget'];
    if (missedValue!=null && budget!=null && vm.filterPercentDiff!=null) {
      var percentage_diff = (Math.abs(missedValue/budget));
      return vm.filterPercentDiff/100 > percentage_diff;
    }
    return false;
  };

  function fetchReportData(params) {
    vm.errors = [];
    vm.isLoading = true;
    var query = {};
    if (vm.filterUsername) {
      query.search = vm.filterUsername;
    }
    query = vm.reportTable.appendOrderingParam(params, query);

    return $http
      .get(apiPrefixUrl + '/missed-cap/report', {params: query})
      .then(function (resp) {
        vm.isLoading = false;
        return resp.data;
      }, function (resp) {
        vm.isLoading = false;
        vm.errors = ['Report data could not be loaded'];
        return [];
      });
  }
}

