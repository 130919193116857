/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'reportsBySalesRepTable', {
      controller: reportsBySalesRepTableCtrl,
      template: require('$views/admin/reports-by-sales-rep/reports-by-sales-rep-table.html').default,
      controllerAs: 'vm',
      bindings: {
        filterSalesmen: '<',
        filterAccountManagers: '<',
        firstStartDate: '<',
        firstEndDate: '<',
        secondStartDate: '<',
        secondEndDate: '<',
        columnsTheme: '<',
        isLoading: '='
      }
    }
  );

reportsBySalesRepTableCtrl.$inject = ['time', '$scope', 'BillingService', 'AdvertiserService'];
function reportsBySalesRepTableCtrl(time, $scope, BillingService, AdvertiserService) {
  var vm = this;

  vm.records = [];
  vm.errors = [];
  vm.isLoading = false;

  $scope.merge = {
      state: {
        salesRep: false,
        advertisers: false,
      },
      rawData: {
        salesRep: null,
        mergeData: null,
        advertisers: null,
      },
      reset: function() {
        var self = $scope.merge;
        self.state.salesRep = false;
        self.state.advertisers = false;

        self.rawData.salesRep = null;
        self.rawData.advertisers = null;
        self.mergeData = null;
      },
      isReady: function() {
        var state = $scope.merge.state;
        return state.salesRep;
      },
      setSalesRep: function(salesRep) {
        var self = $scope.merge;
        self.state.salesRep = true;
        self.rawData.salesRep = salesRep;
        self.parseRawData();
      },
      setAdvertisers: function(advertisers) {
        var self = $scope.merge;
        self.rawData.advertisers = advertisers;
        self.state.advertisers = true;
        $scope.$apply();
      },
      parseRawData: function() {
        var self = $scope.merge;
        if(self.isReady()) {
          vm.summary = {
            first_spend: 0,
            second_spend: 0,
            left_budget: 0,
            right_budget: 0,
            diff_spend: 0,
          };
          var name_counter = 0;
          var advertiser_counter = 0;
          for(var name in self.rawData.salesRep) {
            name_counter++;
            var row = self.rawData.salesRep[name],
              left_fee = row["left_fee"] && parseFloat(row["left_fee"].value) || 0,
              right_fee = row["right_fee"] && parseFloat(row["right_fee"].value) || 0,
              left_budget = row["left_budget"] && parseFloat(row["left_budget"].value) || 0,
              right_budget = row["right_budget"] && parseFloat(row["right_budget"].value) || 0,
              details = [];

            for(var advertiser_id in row["advertisers"]) {
              advertiser_counter++;
              var left_adv_budget = row["advertisers"][advertiser_id]["left_budget"].value,
                right_adv_budget = row["advertisers"][advertiser_id]["right_budget"].value,
                left_adv_fee = row["advertisers"][advertiser_id]["left_fee"].value,
                right_adv_fee = row["advertisers"][advertiser_id]["right_fee"].value;
              if((left_adv_fee != 0 || right_adv_fee != 0)){
                details.push({
                  advertiser_id: advertiser_id,
                  first_spend: left_adv_fee,
                  left_budget: left_adv_budget,
                  second_spend: right_adv_fee,
                  right_budget: right_adv_budget,
                  diff_spend: left_adv_fee - right_adv_fee
                });
              };
            };

            vm.records.push({
              name: name,
              first_spend: left_fee,
              second_spend: right_fee,
              left_budget: left_budget,
              right_budget: right_budget,
              diff_spend: right_fee - left_fee,
              details: details
            });

            vm.summary["first_spend"] += left_fee;
            vm.summary["second_spend"] += right_fee;
            vm.summary["left_budget"] += left_budget;
            vm.summary["right_budget"] += right_budget;
          }
          vm.summary["diff_spend"] = vm.summary["second_spend"] - vm.summary["first_spend"];
          vm.summary["diff_budget"] = vm.summary["right_budget"] - vm.summary["left_budget"];

          vm.isLoading = false;
          $scope.$apply();
        }
      }
  };

  vm.detailedColumns = [
    {field: 'name', title: 'Rep / Advertiser', show: true, row_class: 'bg-warning'},
    {field: 'first_spend', title: 'Spend', show: true, row_class: 'bg-warning'},
    {field: 'left_budget', title: 'Available Budget', show: true, row_class: 'bg-warning'},
    {field: '_', title: '', show: true},
    {field: 'name', title: 'Rep', show: true, row_class: 'bg-info'},
    {field: 'second_spend', title: 'Spend', show: true, row_class: 'bg-info'},
    {field: 'right_budget', title: 'Available Budget', show: true, row_class: 'bg-info'},
    {field: '_', title: '', show: true},
    {field: 'diff_spend', title: 'Spend Difference', show: true}
  ];

  vm.simpleColumns = [
    {field: 'name', title: 'Rep / Advertiser', show: true},
    {field: 'first_spend', title: 'Date 1 Spend', show: true, row_class: 'bg-warning'},
    {field: 'second_spend', title: 'Date 2 Spend', show: true, row_class: 'bg-info'},
    {field: 'diff_spend', title: 'Spend Difference', show: true}
  ];

  vm.columns = vm.simpleColumns;

  vm.$onInit = function () {
    getReportsBySalesRep();
  };

  vm.$onChanges = function (changesObj) {
    if (changesObj.columnsTheme) {
      changeColumns();
    } else {
      getReportsBySalesRep();
    }
  };

  function changeColumns() {
    if (vm.columnsTheme === 'detailed') {
      vm.columns = vm.detailedColumns;
    } else {
      vm.columns = vm.simpleColumns;
    }
  }

  function onError(resp) {
    vm.isLoading = false;
    vm.errors = ['Report could not be loaded'];
  }

  function getReportsBySalesRep() {
    vm.errors = [];
    vm.summary = null;
    vm.isLoading = true;
    vm.records = [];

    var query = {
      left_from_date: time.getBackendDate(vm.firstStartDate),
      left_to_date: time.getBackendDate(vm.firstEndDate),
      right_from_date: time.getBackendDate(vm.secondStartDate),
      right_to_date: time.getBackendDate(vm.secondEndDate)
    };

    if (vm.filterSalesmen && vm.filterSalesmen.length !== 0) {
      query.salesman = vm.filterSalesmen.join(',');
    } else if (vm.filterAccountManagers && vm.filterAccountManagers.length !== 0) {
      query.account_manager = vm.filterAccountManagers.join(',');
    } else {
      vm.isLoading = false;
      vm.errors = ['Please select salesman or account manager'];
      return;
    }

    $scope.merge.reset();
    BillingService.fetchSalesRepBilling(query).then($scope.merge.setSalesRep, onError);
    AdvertiserService.fetchAdvertisers().then($scope.merge.setAdvertisers, onError);
  }
}

