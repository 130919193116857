/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'favoriteUsers', {
      controller: favoriteUsersCtrl,
      template: require('$views/admin/home/favorite-users.html').default,
      controllerAs: 'vm',
      bindings: { onChange: '&' }
    }
  );

favoriteUsersCtrl.$inject = ['$rootScope', '$http', 'apiPrefixUrl', 'FavoriteUsersList', '$window'];
function favoriteUsersCtrl($rootScope, $http, apiPrefixUrl, FavoriteUsersList, $window) {
  var vm = this;
  var url = apiPrefixUrl + '/appnexus/favorite-users';

  vm.users = [];
  vm.selectedUsers = [];
  vm.isLoading = false;
  vm.isError = false;
  vm.promise = null;
  vm.active = null;

  vm.$onInit = function () {
    vm.getUsers();
  };

  $rootScope.$on('favorite-user-list-reload', vm.getUsers);

  vm.selectUsers = function () {
    $http
      .post(url, {user_ids: vm.selectedUsers})
      .then(function (resp) {
        FavoriteUsersList.setSelectedUsers(resp);
        vm.onChange();
      });
  };

  vm.changeActive = function (value) {
    vm.active = value;
    FavoriteUsersList.setActive(value);
    $http
      .post(url, {active: vm.active })
      .then(function () { vm.onChange(); });
  };

  vm.getUsers =function () {
    vm.isError = false;
    vm.isLoading = true;
    vm.users = [];

    return FavoriteUsersList.getFromCache()
      .then(function (resp) {
        vm.isLoading = false;
        vm.users = resp.users;
        vm.active = resp.active;
        vm.selectedUsers = (
          vm.users
            .filter(function (user) {return user.selected; })
            .map(function (user) {return user.user_id;})
        );
      }, function (resp) {
        vm.isLoading = false;
        vm.isError = true;
      });
  }

}

