/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'oldReportsBySalesRepTable', {
      controller: oldReportsBySalesRepTableCtrl,
      template: require('$views/admin/reports-by-sales-rep/old-reports-by-sales-rep-table.html').default,
      controllerAs: 'vm',
      bindings: {
        filterSalesmen: '<',
        filterAccountManagers: '<',
        firstStartDate: '<',
        firstEndDate: '<',
        secondStartDate: '<',
        secondEndDate: '<',
        columnsTheme: '<',
        isLoading: '='
      }
    }
  );

oldReportsBySalesRepTableCtrl.$inject = ['$http', 'apiPrefixUrl', 'time'];
function oldReportsBySalesRepTableCtrl($http, apiPrefixUrl, time) {
  var vm = this;

  vm.records = [];
  vm.errors = [];
  vm.isLoading = false;

  vm.detailedColumns = [
    {field: 'name', title: 'Rep / Advertiser', show: true, row_class: 'bg-warning'},
    {field: 'first_spend', title: 'Spend', show: true, row_class: 'bg-warning'},
    {field: 'first_budget', title: 'Budget', show: true, row_class: 'bg-warning'},
    {field: '_', title: '', show: true},
    {field: 'name', title: 'Rep', show: true, row_class: 'bg-info'},
    {field: 'second_spend', title: 'Spend', show: true, row_class: 'bg-info'},
    {field: 'second_budget', title: 'Budget', show: true, row_class: 'bg-info'},
    {field: '_', title: '', show: true},
    {field: 'diff_spend', title: 'Spend Difference', show: true}
  ];

  vm.simpleColumns = [
    {field: 'name', title: 'Rep / Advertiser', show: true},
    {field: 'first_spend', title: 'Date 1 Spend', show: true, row_class: 'bg-warning'},
    {field: 'second_spend', title: 'Date 2 Spend', show: true, row_class: 'bg-info'},
    {field: 'diff_spend', title: 'Spend Difference', show: true}
  ];

  vm.columns = vm.simpleColumns;

  vm.$onInit = function () {
    getReportsBySalesRep();
  };

  vm.$onChanges = function (changesObj) {
    if (changesObj.columnsTheme) {
      changeColumns();
    } else {
      getReportsBySalesRep();
    }
  };

  function changeColumns() {
    if (vm.columnsTheme === 'detailed') {
      vm.columns = vm.detailedColumns;
    } else {
      vm.columns = vm.simpleColumns;
    }
  }

  function getReportsBySalesRep() {
    vm.errors = [];
    vm.summary = null;
    vm.isLoading = true;
    vm.records = [];

    var query = {
      first_date_start: time.getBackendDate(vm.firstStartDate),
      first_date_end: time.getBackendDate(vm.firstEndDate),
      second_date_start: time.getBackendDate(vm.secondStartDate),
      second_date_end: time.getBackendDate(vm.secondEndDate)
    };

    if (vm.filterSalesmen && vm.filterSalesmen.length !== 0) {
      query.salesman = vm.filterSalesmen.join(',');
    } else if (vm.filterAccountManagers && vm.filterAccountManagers.length !== 0) {
      query.account_manager = vm.filterAccountManagers.join(',');
    } else {
      vm.isLoading = false;
      vm.errors = ['Please select salesman or account manager'];
      return;
    }

    return $http
      .get(apiPrefixUrl + '/reports-by-sales-rep/', {params: query})
      .then(function (resp) {
        console.log(resp);
        var data = resp.data;
        vm.isLoading = false;
        if (data.records.length > 0) {
          vm.summary = data.summary;
        } else {
          vm.summary = null;
        }
        vm.records = data.records;
      }, function (resp) {
        vm.isLoading = false;
        vm.errors = ['Report could not be loaded'];
      });
  }
}

