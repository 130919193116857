/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('DeleteCreativesCtrl', ['$scope', '$http', 'apiPrefixUrl',
    function ($scope, $http, apiPrefixUrl) {
      /*
          Use of this function in actions used inside popover, solves
          issue from detaching event target from popover (otherwise popover
          directive is unable to recognize if element is inside popover or not).

          Reason why this works is unknown in details. Basically there must be
          a conflict in angular processes:

          after clicking on element which triggers ajax operations, somehow
          angular detach this element from dom document in this same time
          where popoverTemplate directive is checking if that element is inside
          document or not, to decide to close window or not, so is
          closing the window.
      */
      var async = function(callback){
          setTimeout(function(){
              $scope.$apply(function(){
                  callback();
              });
          }, 10);
      };

      $scope.initModal = function () {
        $scope.state = 'before';
      };

      $scope.deleteCreatives = function (line_item_id) {
        async(function() {
          var url = apiPrefixUrl + '/appnexus/line-items/' + line_item_id + '/clear_creatives';
          $scope.state = 'deleting';
          $http({method: 'PUT', url: url})
            .then(function(resp){
              $scope.state = 'done';
              $scope.lineItem.creatives_count = 0;
            }, function(){
              $scope.state = 'error';
            });
          });
      };
      $scope.$on('popover-opened', $scope.initModal);
    }]);

