/*** IMPORTS FROM imports-loader ***/


'use strict';
angular
  .module('adhesiveApp')
  .component(
    'monthlyReportsByAdvTable', {
      controller: monthlyReportsByAdvTableCtrl,
      template: require('$views/admin/monthly-reports-by-adv/table.html').default,
      controllerAs: 'vm',
      bindings: {
        filterId: '<',
        month: '<',
        isLoading: '='
      }
    }
  );

monthlyReportsByAdvTableCtrl.$inject = ['$http', 'BillingService', 'AdvertiserService'];
function monthlyReportsByAdvTableCtrl($http, BillingService, AdvertiserService) {
  var vm = this;
  vm.errors = [];
  vm.isLoading = false;
  vm.filterId = '';

  clearData();

  vm.columns = [
    {field: 'day', title: 'Day', show: true},
    {field: 'total_convs', title: 'Total conv', show: true},
    {field: 'purchase_convs', title: 'Hive conv', show: true},
    {field: 'convs_ratio', title: '% of conv', show: true},
    {field: 'spend', title: 'spend', show: true},
    {field: 'convs_value', title: 'Total conv value', show: true},
    {field: 'roas', title: 'ROAS', show: true},
    {field: 'cpa', title: 'CPA', show: true},
    {field: 'visitor_convs', title: 'Visitors', show: true},
    {field: 'visitor_convs_rate', title: 'Conv rate', show: true},
    {field: 'avg_order_value', title: 'AOV', show: true},
    {field: 'media_cost', title: 'Media cost', show: true},
    {field: 'cogs', title: 'Cogs %', show: true}
  ];

  vm.$onChanges = function (changesObj) {
    getMonthlyReportsByAdv();
  };

  function getMonthlyReportsByAdv(params) {
    vm.errors = [];
    vm.month_summary = null;
    vm.isLoading = true;
    vm.advertiser = null;

    clearData();

    let advertiser_id = null,
      month = null;
    if (vm.filterId) {
      advertiser_id = vm.filterId;
      AdvertiserService.getAdvertiser(advertiser_id).then(
        (advertiser) => {
          vm.advertiser = advertiser;
        }
      );

    } else {
      vm.isLoading = false;
      vm.errors = ['Please select an advertiser'];
      clearData();
      return;
    }

    if (vm.month) {
      month = vm.month;
    }

    BillingService.fetchMonthlyReportByAdvertiser(advertiser_id, month).then(
      (data) => {
        vm.isLoading = false;
        var length_records = data.week_records.length || 0;
        if (length_records === 0) {
          clearData();
          return;
        }

        vm.month_summary = data.month_summary;
        vm.week_records = data.week_records;
      }, (resp) => {
        vm.isLoading = false;
        vm.errors = ['Report could not be loaded'];
        clearData();
      });
  }

  function clearData() {
    vm.day_records = null;
    vm.week_records = null;
    vm.month_summary = null;
  }

}

