/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').controller('UserFormCtrl', [
        '$scope', 'UserModel', 'USER_TYPES', 'userServ', 'SegmentList',
        function($scope, UserModel, USER_TYPES, userServ, SegmentList) {

    function disinfectUser() {
        if(!$scope.isAdvertiser()) {
            delete $scope.user.advertiser_profile;
        }
    }

    function splitEmails() {
      if(!$scope.isAdvertiser()) { return; }
      var advertiser = $scope.user.advertiser_profile;
      var emails = advertiser.low_balance_alert_emails;

      if (angular.isArray(emails)) {
        return;
      }

      if (!angular.isString(emails)) {
        advertiser.low_balance_alert_emails = [];
        return;
      }

      advertiser.low_balance_alert_emails = (
        emails.split(',').map(
          function (email) { return email.trim().toLowerCase(); }
        ).filter(
          function (email, index, self) {
            return !!email && self.indexOf(email) === index;
          }
        )
      );
    }

    $scope.USER_TYPES = USER_TYPES;
    $scope.flags = {
        delete: false
    };

    $scope.havePredefinedUser = function(){
        return !!$scope.$parent.user;
    };

    $scope.hasNotificationRights = function() {
        return userServ.isAdmin() && ($scope.user.permission_level === userServ.types.superadmin.id ||
            $scope.user.permission_level === userServ.types.admin.id);
    };

    $scope.init = function() {
        $scope.password_confirm = '';
        $scope.send = false;
        $scope.msgs = [];
        var user = $scope.user = new UserModel();
        user.advertiser_profile = {};

        if($scope.havePredefinedUser()){
            var parentUser = $scope.$parent.user;
            user.id = parentUser.id;
            user.email = parentUser.email;
            user.permission_level = parentUser.permission_level;
            user.notifications_enabled = parentUser.notifications_enabled;
            if (parentUser.advertiser_profile) {
                var parentAdvertiser = parentUser.advertiser_profile;
                var advertiser = user.advertiser_profile;

                advertiser.advertiser_username = parentAdvertiser.advertiser_username;
                advertiser.advertiser_id = parentAdvertiser.advertiser_id;
                advertiser.advertiser_status = parentAdvertiser.advertiser_status;
                advertiser.autopiggybacking_enabled = parentAdvertiser.autopiggybacking_enabled;
                advertiser.low_balance_alert_emails = (
                  parentAdvertiser.low_balance_alert_emails.join(', ')
                );
            }
        } else {
            $scope.segments = [];

            SegmentList.getCachedSegmentList().then(function(segments) {
                $scope.segments = segments;
            });
        }
    };

    $scope.init();

    $scope.isAdvertiser = function() {
        return $scope.user.permission_level == 4;
    };

    $scope.handleErrorResponse = function(response){
        $scope.msgs = [];
        if(angular.isObject(response.data)){
            if(response.data.advertiser_profile) {
                if(response.data.advertiser_profile.advertiser_id) {
                    $scope.form.advertiser_id.$setValidity('required', false);
                    $scope.msgs.push([response.data.advertiser_profile.advertiser_id.join(' '), 'alert-danger']);
                }
                if(response.data.advertiser_profile.advertiser_username) {
                    $scope.form.advertiser_username.$setValidity('required', false);
                    $scope.msgs.push([response.data.advertiser_profile.advertiser_username.join(' '), 'alert-danger']);
                }
            }
            if(response.data.permission_level) {
                $scope.form.permission_level.$setValidity('required', false);
                $scope.msgs.push([response.data.permission_level.join(' '), 'alert-danger']);
            }
            if(response.data.password) {
                $scope.form.password.$setValidity('required', false);
                $scope.form.password_confirm.$setValidity('required', false);
                $scope.msgs.push([response.data.password.join(' '), 'alert-danger']);
            }
            if(response.data.email) {
                $scope.form.email.$setValidity('required', false);
                $scope.msgs.push([response.data.email.join(' '), 'alert-danger']);
            }
            if(response.data.advertiser_profile &&
               response.data.advertiser_profile.low_balance_alert_emails) {
               $scope.msgs.push([
                 response.data.advertiser_profile.low_balance_alert_emails.join(' '),
                 'alert-danger'
               ])
            }
        }
        else {
            $scope.msgs = [['Server error. Please try again later.', 'alert-danger']];
        }
    };

    $scope.save = function(){
        if($scope.user.password !== $scope.password_confirm) {
            $scope.user.password = '';
            $scope.password_confirm = '';
            $scope.form.password.$setValidity('required', false);
            $scope.form.password_confirm.$setValidity('required', false);
        }

        if($scope.form.$invalid){
            $scope.msgs = [['Please check that all required fields are filled', 'alert-danger']];
            return false;
        }

        $scope.msgs = [['Saving...', 'alert-info']];

        disinfectUser();
        splitEmails();

        $scope.user.$save(function(){
            $scope.closePopover();
            $scope.$emit('user-list-reload');
            $scope.$emit('favorite-user-list-reload');
            $scope.$emit('msg:admin', ['User saved', 'alert-success']);

        }, $scope.handleErrorResponse);
    };

    $scope.delete = function(){
        $scope.msgs = [['Removing...', 'alert-info']];

        $scope.user.$delete(
            function(){
                $scope.closePopover();
                $scope.$emit('user-list-reload');
                $scope.$emit('favorite-user-list-reload');
                $scope.$emit('msg:admin', ['User removed', 'alert-success']);
            },
            function(response){
                if(response.data && response.data.msg){
                    $scope.msgs = [[response.data.msg, 'alert-danger']];
                }
                else {
                    $scope.msgs = [['Server error. Please try again later', 'alert-danger']];
                }
            }
        );
   };

    $scope.update = function(){
        if($scope.form.$invalid){
            $scope.msgs = [['Please check that all required fields are filled', 'alert-danger']];
            return false;
        };

        $scope.msgs = [['Updating...', 'alert-info']];

        disinfectUser();
        splitEmails();


        $scope.user.$update(function(){
            $scope.closePopover();
            $scope.$emit('user-list-reload');
            $scope.$emit('favorite-user-list-reload');
            $scope.$emit('msg:admin', ['User updated', 'alert-success']);
        }, $scope.handleErrorResponse);
    };

    $scope.submit = function($event){
        // setup flags
        $scope.send = true;
        $scope.msgs = [];
        $event.preventDefault();

        // do saving / updating
        if($scope.$parent.user){
            if($scope.flags.delete){
                $scope.delete();
            }
            else{
                $scope.update();
            }
        }
        else {
            $scope.save();
        }
    };

    $scope.$on('popover-opened', $scope.init);
}]);

