/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('LineItemLogCtrl', ['$scope', '$http', 'apiPrefixUrl',
        function ($scope, $http, apiPrefixUrl) {

            $scope.logs = [];
            var camp = $scope.$parent.lineItem;

            if(!camp){
               return null;
            }

            $scope.page = {
                current: 1,
                size: 10,
                count: 1
            };

            $scope.loadLogs = function(){
                $scope.logs = [];
                $scope.msg = ['Loading...', 'alert-info'];

                $http.get(apiPrefixUrl + '/appnexus/line_item_logs', {
                    params: {
                        page: $scope.page.current,
                        page_size: $scope.page.size,
                        line_item_id: camp.key
                    }
                })
                    .then(function(resp){
                        var data = resp.data;
                        $scope.logs = data.results;
                        $scope.totalItems = data.count;
                        $scope.page.count = Math.ceil(data.count / $scope.page.size) || 1;
                        $scope.msg = [];
                    }, function(){
                        $scope.msg = ['Server communication error', 'alert-danger'];
                    });
            };

            $scope.$on('popover-opened', $scope.loadLogs);

            /*
                Use of this function in actions used inside popover, solves
                issue from detaching event target from popover (otherwise popover
                directive is unable to recognize if element is inside popover or not).

                Reason why this works is unknown in details. Basically there must be
                a conflict in angular processes:

                after clicking on element which triggers ajax operations, somehow
                angular detach this element from dom document in this same time
                where popoverTemplate directive is checking if that element is inside
                document or not, to decide to close window or not, so is
                closing the window.
             */
            var async = function(callback){
                setTimeout(function(){
                    $scope.$apply(function(){
                        callback();
                    });
                }, 10);
            }

            $scope.nextPage = function(){
                async(function(){
                    $scope.page.current++;
                });
            }

            $scope.prevPage = function(){
                async(function(){
                    $scope.page.current--;
                });
            }

            $scope.$watch('page.current', function(nv, ov){
                if(nv == ov){
                    return null;
                }

                $scope.loadLogs();
            });
    }]);

