/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'monthlyReportsByAdvMonthPicker', {
      controller: monthlyReportsByAdvMonthPickerCtrl,
      template: require('$views/admin/monthly-reports-by-adv/month-picker.html').default,
      controllerAs: 'vm',
      bindings: {
        onChange: '&',
        month: '<'
      }
    }
  );

monthlyReportsByAdvMonthPickerCtrl.$inject = ['time'];
function monthlyReportsByAdvMonthPickerCtrl(time) {
  var vm = this;
  vm.months = [];
  vm.month = null;

  vm.$onInit = function () {
    vm.months = generateMonthsRange();
    vm.month = vm.month || vm.months[0].value;
  };

  vm.setMonth = function () {
    vm.onChange({month: vm.month});
  };

  function generateMonthsRange () {
    var today = time.getUtcNowDate();
    var months = [];
    for (var i = 0; i < 6; i++) {
      var month = new Date(today.getFullYear(), today.getMonth() - i);
      months.push({
        value: time.getBackendMonth(month),
        americanValue: time.getAmericanMonth(month)
      });
    }
    return months;
  }


}

