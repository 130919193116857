/*** IMPORTS FROM imports-loader ***/


"use strict";
var switchUrl = function(url) {
  return function($scope, newFrontendPrefix) {
    $("#mainiframe")[0].src = newFrontendPrefix +'#'+ url;
    $(".vueframe").show();

    $scope.$on('$destroy', function () {
      $(".vueframe").hide();
    })
  };
}

var addVueView = function(name, url) {
  let module = angular.module('adhesiveApp');
  module.controller(name, ['$scope', 'newFrontendPrefix', switchUrl(url)])
};

addVueView('NfAdvertisersCtrl', "/admin/advertiser/list");
addVueView('NfFeaturesCtrl', "/admin/advertiser/features");

