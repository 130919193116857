/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .controller('PiggyCtrl',
    ['$scope', 'TagService', 'userServ', '$uibModal', 'SegmentList',
      'piggybackUrls', '$routeParams', '$anchorScroll', '$timeout',
    function ($scope, TagService, userServ, $modal, SegmentList,
              piggybackUrls, $routeParams, $anchorScroll, $timeout) {
      $scope.deletedTags = {};
      $scope.piggyForm = {};
      $scope.piggybackUrls = piggybackUrls;
      $scope.piggyTagTypes = ['js', 'img'];
      $scope.subCategories = [];
      $scope.websiteUuid = $routeParams.website_uuid;
      clearAddSite();

      function clearAddSite() {
        $scope.addsite = {site_url: '', segment: null};
      }

      function emitMessage (message, type) {
        type = type || 'success';
        return $scope.$emit('msg:campaigns', [message, 'alert-' + type]);
      }

      SegmentList
        .getCachedSegmentList()
        .then(function(segments) {
          $scope.segmentList = segments;
          $scope.segmentsById = {};
          $scope.segmentList.forEach(function (segment) {
            if (!segment.subsegments) {
              return;
            }
            segment.subsegments.forEach(function (subsegment) {
              subsegment.category = segment.name;
              $scope.subCategories.push(subsegment);
            });
            $scope.segmentsById[segment.id] = segment.name;
          });
        }, function() {
          emitMessage('Server error', 'danger');
        });

      $scope.deleteSite = function (id) {
        loadingSite();
        TagService
          .deleteSite(id)
          .then(deleteSite, errorSite);

        function deleteSite (resp) {
          $scope.loadingSite = false;
          emitMessage('Site has been deleted', 'danger');
          var advertiser = $scope.sites.advertiser_profile;
          advertiser.websites = advertiser.websites.filter(
            function (website) { return website.id !== id; }
          );
        }
      };

      $scope.deleteSiteModal = function (id) {
        var modalInstance = $modal.open({
          animation: $scope.animationsEnabled,
          template: require('$views/admin/modal.html').default
        });

        modalInstance.result.then(function () {
            $scope.deleteSite(id);
        });
      };

      function createEmptyTag (tagType) {
        var normalTags = [
          'conversion_tags',
          'universal_tags',
          'amp_conversion_tags',
          'amp_universal_tags'
        ];
        var isNormalTag = normalTags.indexOf(tagType) != -1;

        if (isNormalTag) {
          return {url: '', type: 'img'};
        }
        if (tagType === 'universal_keywords_tags_with_empty_tag' ) {
          return {
            subsegment: {id: null},
            keyword_list: '',
            universal_tags: [createEmptyTag('universal_tags')]
          };
        }
        if (tagType === 'universal_keywords_tags' ) {
          return {
            subsegment: {id: null},
            keyword_list: '',
            universal_tags: []
          };
        }
        return undefined;
      }

      $scope.populateEmpty = function (siteArr) {
        var websites = siteArr.advertiser_profile.websites;
        websites.forEach(function (website) {
          pushTagIfArrayIsEmpty(website.conversion_tags, 'conversion_tags');
          pushTagIfArrayIsEmpty(website.universal_tags, 'universal_tags');
          pushTagIfArrayIsEmpty(
            website.universal_keywords_tags,
            'universal_keywords_tags_with_empty_tag'
          );
          pushTagIfArrayIsEmpty(website.amp_conversion_tags, 'amp_conversion_tags');
          pushTagIfArrayIsEmpty(website.amp_universal_tags, 'amp_universal_tags');

          function pushTagIfArrayIsEmpty (array, tagType) {
            if (array.length === 0){
              array.push(createEmptyTag(tagType));
            }
          }
        });

      };

      function errorSite (resp) {
        $scope.loadingSite = false;
        emitMessage('Server error', 'danger');
      }

      function loadingSite () {
        $scope.loadingSite = true;
        emitMessage('Saving', 'info');
      }

      $scope.addSite = function(){
        var advertiserId = $scope.sites.advertiser_profile.id;
        var siteUrl = $scope.addsite.site_url;
        var segment = $scope.addsite.segment;
        clearAddSite();
        loadingSite();
        TagService
          .addSite({siteName: siteUrl, segmentId: segment}, advertiserId)
          .then(afterAddSite, errorSite);

        function afterAddSite (resp) {
          $scope.loadingSite = false;
          emitMessage('Site has been created', 'success');
          modifyWebsiteAfterGet(resp.data);
          $scope.sites.advertiser_profile.websites.unshift(resp.data);
        }

      };

      $scope.editSite = function(name, siteId){
        loadingSite();
        TagService
          .editSite(name, siteId)
          .then(afterEditSite, errorSite);
      };

      $scope.updateSegmentWebsite = function (website) {
        loadingSite();
        TagService
          .updateSegmentSite(website.segment, website.id)
          .then(afterEditSite, errorSite);

      };

      function afterEditSite (resp) {
        var siteId = resp.data.id;
        $scope.loadingSite = false;
        emitMessage('Site has been edited', 'success');
        var websites = $scope.sites.advertiser_profile.websites;
        websites.some(function (website) {
          if (website.id === siteId) {
            website.name = resp.data.name;
            website.segment = resp.data.segment;
            modifyWebsiteAfterGet(website);
            return true; // break loop
          }
        });
      }

      $scope.addPiggyback = function(type, id, innerID) {
        var websites = $scope.sites.advertiser_profile.websites;
        var website = websites[id];
        var tag;
        switch(type) {
          case "conversion_tags":
          case "universal_tags":
          case "amp_universal_tags":
          case "amp_conversion_tags":
            tag = createEmptyTag(type);
            website[type].push(tag);
            break;
          case "universal_keywords_tags":
            tag = createEmptyTag('universal_tags');
            website[type][innerID].universal_tags.push(tag);
            break;
          case 'keyword':
            tag = createEmptyTag('universal_keywords_tags');
            website.universal_keywords_tags.unshift(tag);
            break;
        }
      };

      $scope.removeKeyword = function(siteId, keywordId){
        var websites = $scope.sites.advertiser_profile.websites;
        var universal_keywords_tags = websites[siteId].universal_keywords_tags;
        var universal_keywords_tag = universal_keywords_tags[keywordId];
        var universal_keywords_tag_id = universal_keywords_tag.id;
        var deletedTags = $scope.deletedTags;
        if(universal_keywords_tag_id){
          deletedTags.remove_keyword_lists = deletedTags.remove_keyword_lists || [];
          deletedTags.remove_keyword_lists.push(universal_keywords_tag_id);

          removeUniversalTagsFromRemovedTagList(universal_keywords_tag);
        }
        universal_keywords_tags.splice(keywordId, 1);
      };

      function removeUniversalTagsFromRemovedTagList (keywordList) {
        // remove tags from remove_tag list when whole list is removed
        // because we cant remove tag when list are removed in backend
        var tagIds = keywordList.removed_universal_tags || [];
        var deletedTags = $scope.deletedTags;
        var removeTags = deletedTags.universal_remove_tags || [];
        removeTags = removeTags.filter(function (id) { return tagIds.indexOf(id) === -1; });

        if (removeTags.length !== 0) {
          deletedTags.universal_remove_tags = removeTags;
        } else {
          deletedTags.universal_remove_tags = null;
        }
      }

      $scope.removePiggy = function(type, siteIndex, tagIndex, keywordInnerIndex){
        var tagType = type + '_tags';
        var website = $scope.sites.advertiser_profile.websites[siteIndex];
        switch(type){
          case 'conversion':
          case 'universal':
          case "amp_conversion":
          case "amp_universal":
            removeTags(website[tagType], type);
            break;
          case 'universal_keywords':
            var universalKeyword = website[tagType][keywordInnerIndex];
            var tagId = removeTags(universalKeyword.universal_tags, 'universal');
            if (tagId) {
              universalKeyword.removed_universal_tags = universalKeyword.removed_universal_tags || [];
              universalKeyword.removed_universal_tags.push(tagId);
            }
            break;
          case 'image_con':
            removeTag(website.img_conversion_tag, 'conversion');
            break;
          case 'image_uni':
            removeTag(website.img_universal_tag, 'universal');
            break;
        }

        function removeTags (tags, type) {
          var tag = tags[tagIndex];
          if (tag.id) {
            var removeKey = type + '_remove_tags';
            $scope.deletedTags[removeKey] = $scope.deletedTags[removeKey] || [];
            $scope.deletedTags[removeKey].push(tag.id);
          }
          tags.splice(tagIndex, 1);
          return tag.id || null;
        }

        function removeTag (tag, type) {
          tag.url = '';
          if (tag.id) {
            var removeKey = type + '_remove_tag';
            $scope.deletedTags[removeKey] = tag.id;
          }
          return tag.id || null;
        }
      };

      $scope.addChange = function(type, siteId, tagId, keywordInnerId){
        var tagType = type + '_tags';
        var website = $scope.sites.advertiser_profile.websites[siteId];
        switch(type){
          case 'conversion':
          case 'universal':
          case "amp_conversion":
          case "amp_universal":
              website[tagType][tagId].edited = true;
              break;
          case 'universal_keywords':
              website[tagType][keywordInnerId].universal_tags[tagId].edited = true;
              break;
          case 'image_con':
              website.img_conversion_tag.edited = true;
              break;
          case 'image_uni':
              website.img_universal_tag.edited = true;
              break;
          case 'keyword_list':
              website.universal_keywords_tags[tagId].edited = true;
              break;
          case 'keywords_subCategory':
              website.universal_keywords_tags[tagId].editedSubCategory = true;
              break;
        }
      };

      $scope.prepareUniversalJSONtoSend = function(type, index){
        var currentWebsite = $scope.sites.advertiser_profile.websites[index];
        var json = {};
        var websiteID = currentWebsite.id;

        var passArr = getWebsiteArrayByType();
        pushEditedAndAddedTags(type, passArr, currentWebsite, json, websiteID, $scope.data.id);

        function getWebsiteArrayByType () {
          switch (type) {
            case 'js_universal': return currentWebsite.universal_tags;
            case 'js_conversion': return currentWebsite.conversion_tags;
            case 'img_universal': return currentWebsite.img_universal_tag;
            case 'img_conversion': return currentWebsite.img_conversion_tag;
            case "amp_conversion": return currentWebsite.amp_conversion_tags;
            case "amp_universal": return currentWebsite.amp_universal_tags;
            default: return undefined;
          }
        }

        function pushChangesHelper (array, json, websiteID, additionalData){
          additionalData = additionalData || {};
          array.forEach(function (obj) {
            var current = angular.copy(obj);
            if (!current.id && current.edited) {
              current.website = websiteID;
              angular.extend(current, additionalData);
              delete current.edited;
              json.add_tags = json.add_tags || [];
              json.add_tags.push(current);
            } else if (current.edited) {
              json.update_tags = json.update_tags || [];
              current.website = websiteID;
              delete current.edited;
              json.update_tags.push(current);
            }
          });
        }

        function hasEdited (array) {
          return array.some(function (obj) { return !!obj.edited; });
        }

        function pushEditedAndAddedTags (type, array, currentWebsite, json, websiteID, advertiserId){
          var removeKey;
          switch(type) {
            case 'amp_conversion':
            case 'amp_universal':
              pushChangesHelper(array, json, websiteID);
              removeKey = type + '_remove_tags';
              json.remove_tags = $scope.deletedTags[removeKey];
              $scope.sendJsonToBackend(type, json, advertiserId);
              break;
            case 'js_universal':
              pushEditedAndAddedTags('js_universal_keywords',
                currentWebsite.universal_keywords_tags, currentWebsite, json, websiteID, advertiserId);
            case 'js_conversion':
              pushChangesHelper(array, json, websiteID);
              removeKey = type.substr(3) + '_remove_tags';
              json.remove_tags = $scope.deletedTags[removeKey];
              $scope.sendJsonToBackend(type, json, advertiserId);
              break;
            case 'img_universal':
            case 'img_conversion':
              if(!array.id){
                json.add_tag = angular.copy(array);
                json.add_tag.website = websiteID;
                delete json.add_tag.edited;
              } else if (array.edited){
                json.update_tag = angular.copy(array);
                delete json.update_tag.edited;
              }
              removeKey = type.substr(4) + '_remove_tag';
              json.remove_tag = $scope.deletedTags[removeKey];
              $scope.sendJsonToBackend(type, json, advertiserId);
              break;
            case 'js_universal_keywords':
              array.forEach(function (obj) {
                var current = angular.copy(obj);
                var hasReallyEdited = (
                  !current.id && (
                    current.edited ||
                    hasEdited(current.universal_tags) ||
                    current.editedSubCategory
                  )
                );
                if (hasReallyEdited) {
                    json.add_keywords_tags = json.add_keywords_tags || [];
                    current.universal_tags = current.universal_tags.map(function (obj) {
                      var newObj = angular.copy(obj);
                      newObj.website = websiteID;
                      delete newObj.edited;
                      return newObj;
                    });
                    json.add_keywords_tags.push({
                      keywords: {keyword_list: current.keyword_list},
                      tags: current.universal_tags,
                      subsegment: {
                        subsegment: current.subsegment.id,
                        website: websiteID
                      }
                    });
                } else {
                    if(current.edited){
                      json.update_keyword_lists = json.update_keyword_lists || [];
                      json.update_keyword_lists.push({
                        id: current.id,
                        keyword_list: current.keyword_list
                      });
                    }
                    if(current.editedSubCategory){
                      json.update_subsegment = json.update_subsegment || [];
                      json.update_subsegment.push({
                        keywords: current.id,
                        subsegment: current.subsegment.id,
                        website: websiteID
                      });
                    }
                    pushChangesHelper(current.universal_tags, json, websiteID, {keywords: current.id});
                  }
              });
              json.remove_keyword_lists = $scope.deletedTags.remove_keyword_lists;
              break;
          }
        }

      };

      $scope.sendJsonToBackend = function (type, json, advertiserId) {
        if(Object.keys(json).length === 0) {
          return;
        }
        $scope.loadingTags = true;
        $scope.errorTags = {};
        emitMessage("Saving settings...", 'info');
        TagService
          .changeTagsForSite(type, json, advertiserId)
          .then(
            function (resp) {
              $scope.loadingTags = false;
              updateSites(resp);
              emitMessage("Settings updated", 'success');
              $scope.deletedTags = {};
            },
            function (resp){
              $scope.loadingTags = false;
              var data = resp.data;
              var error;
              if (data.keyword_list) {
                error = data.keyword_list[0];
              } else if (data.data) {
                error = data.data;
              } else if (typeof data === 'string') {
                error = data;
              } else if (data.url) {
                error = data.url[0];
              } else {
                error = 'Server Error';
              }
              emitMessage(error, 'danger');
              $scope.errorTags[type] = error;
        });
      };

      $scope.getSites = function(id){
        TagService
          .getSites(id)
          .then(function (resp) {
            updateSites(resp);
            scrollToSelectedWebsite();
          });
      };

      function updateSites (resp) {
        $scope.sites = resp.data;
        $scope.sites.advertiser_profile.websites.forEach(modifyWebsiteAfterGet);
        $scope.populateEmpty($scope.sites);
      }

      function modifyWebsiteAfterGet (website) {
        website.hasSegment = !!website.segment;
      }

      function scrollToSelectedWebsite () {
        var websites = $scope.sites.advertiser_profile.websites;
        var selectedWebsiteUuid = $routeParams.website_uuid;

        if (!selectedWebsiteUuid) {
          websites.forEach(openFirstWebsite);
        } else {
          websites.forEach(openSelectedWebsite);
          $timeout(scroll);
        }

        function openFirstWebsite (website, index) {
          website.isOpen = index === 0;
        }

        function openSelectedWebsite (website) {
          website.isOpen = website.identity === selectedWebsiteUuid;
        }

        function scroll () {
          $anchorScroll('website-' + selectedWebsiteUuid);
        }

      }

      $scope.$on('parentControllerLoaded',function(){
        $scope.getSites($scope.data.id);
      });

}]);

