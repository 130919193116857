/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('adminCampaignsHeadForm', {
    controller: adminCampaignsHeadFormCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/campaigns/head-form.html').default,
    bindings: {
      advertiserProfile: '<',
      campaignFields: '<'
    }
  });

adminCampaignsHeadFormCtrl.$inject = ['$scope', 'PRICING_MODELS', 'apiPrefixUrl', '$http', 'WaterJwt'];

function adminCampaignsHeadFormCtrl ($scope, PRICING_MODELS, apiPrefixUrl, $http, WaterJwt) {
  var vm = this,
    submitUrl = apiPrefixUrl + '/appnexus/save_advertiser_labels';

  vm.pricingModels = Object.entries(PRICING_MODELS).map(function (arr) {
    var key = arr[0];
    var value = arr[1];
    return {label: value, id: Number(key)};
  });

  vm.PACE_EVENLY_ID = 1;
  vm.PACE_FAST_AS_POSSIBLE_ID = 2;

  vm.$onChanges = function (changes) {
    if (changes.campaignFields) {
      // popover has a $scope, so I must put columnsMenu to $scope
      $scope.columnsMenu = [[], []];
      vm.campaignFields.forEach(function(col, i) {
        $scope.columnsMenu[i%2].push(col);
      });
    }

    if (vm.advertiserProfile) {
      $scope.formData = {
        advertiser_id: vm.advertiserProfile.advertiser_id,
        advertiser_daily_budget: vm.advertiserProfile.advertiser_daily_budget,
        low_balance_alert: vm.advertiserProfile.low_balance_alert,
        show_conversion_report: vm.advertiserProfile.show_conversion_report,
        advertiser_pace: vm.advertiserProfile.advertiser_pace,
        roas_goal: vm.advertiserProfile.roas_goal,
        monthly_budget: vm.advertiserProfile.monthly_budget,
        advertiser_cpa_goal: vm.advertiserProfile.advertiser_cpa_goal,
        advertiser_is_contributing: vm.advertiserProfile.advertiser_is_contributing,
        business_relationship: vm.advertiserProfile.business_relationship || false
      };
    }
  };


  $scope.submitHeadForm = function() {
    var options = {cache: true};
    $http
      .post(submitUrl, $scope.formData, options)
      .then(function (resp) {
          $scope.msgs = [["Campaigns data save successful", 'alert-success']];
      }, function (resp) {
        var errorMsg = "Can't save campaigns data";
        $scope.msgs = [[errorMsg, 'alert-danger']];
        console.error(resp);
      });
  };
}

