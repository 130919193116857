/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('tagsMonitoringTable', {
    controller: tagsMonitoringTableCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/tags-monitoring/table.html').default,
    bindings: {
      filterSearch: '<',
      page: '<',
      onChangePagesCount: '&',
      onChangeSort: '&'
    }
  });


tagsMonitoringTableCtrl.$inject = ['apiPrefixUrl', '$http', '$q', 'piggybackUrls', 'userServ'];
function tagsMonitoringTableCtrl(apiPrefixUrl, $http, $q, piggybackUrls, userServ) {
  var vm = this;

  vm.isAdmin = userServ.isAdmin();

  vm.sort = 'website__name';
  vm.canceler = null;
  vm.isLoading = false;
  vm.piggybackUrls = piggybackUrls;

  vm.$onInit = function () {
    clearData();
  };

  vm.$onChanges = function () {
    loadData();
  };

  vm.changeSort = function (value) {
    if (vm.sort === '-' + value) {
      vm.sort = value;
    } else {
      vm.sort = '-' + value;
    }
    vm.onChangeSort({sort: vm.sort});
    if (vm.page === 1) {
      loadData();
    }
  };

  vm.columns = [
    {field: 'salesperson', title: 'Sales Rep', overmenu: 2, rowspan: 2,
      sortable: 'website__advertiser_profile__salesperson', show: true},
    {field: 'advertiser_name', title: 'Advertiser', overmenu: true, rowspan: 2,
      sortable: 'website__advertiser_profile__advertiser_username', show: true},
    {field: 'website', title: 'Website', sortable: 'website__name',
      overmenu: true, rowspan: 2, show: true},
    {title: 'Last Tag Type', show: false, overmenu: true, colspan: 2},
    {title: 'Visits', show: false, overmenu: true, colspan: 3},
    {title: 'Conversions', show: false, overmenu: true, colspan: 3},
    {field: 'visits_last_tag_type', title: 'Visits', show: true},
    {field: 'conversions_last_tag_type', title: 'Conversions', show: true},
    {field: 'visits_last_hour', title: 'Last Hour', show: true,
      sortable: 'visits_last_hour'},
    {field: 'visits_last_day', title: 'Last 24H', show: true,
      sortable: 'visits_last_day'},
    {field: 'visits_day_delta', title: '24H Delta', show: true,
      sortable: 'visits_day_delta'},
    {field: 'conversions_last_day', title: 'Last 24H', show: true,
      sortable: 'conversions_last_day'},
    {field: 'conversions_last_week', title: 'Last Week', show: true,
      sortable: 'conversions_last_week'},
    {field: 'conversions_week_delta', title: 'Week Delta', show: true,
      sortable: 'conversions_week_delta'}
  ];

  function clearData () {
    vm.data = null;
    vm.errors = null;
  }

  function loadData () {
    clearData();
    vm.isLoading = true;
    if (vm.canceler) {
      vm.canceler.resolve();
    }
    vm.canceler = $q.defer();

    var query = {
      ordering: vm.sort,
      page: vm.page
    };

    if (vm.filterSearch) {
      query.search = vm.filterSearch;
    }

    vm.request = $http
      .get(apiPrefixUrl + '/account-health-monitoring/tags-monitoring', {
        params: query,
        timeout: vm.canceler.promise
      })
      .then(function (resp) {
        vm.isLoading = false;
        vm.onChangePagesCount({pagesCount: Math.ceil(resp.data.count / 20)});
        vm.data = resp.data.results || [];
        modifyData(vm.data);
      }, function (resp) {
        if (resp.status < 0) {
          return;
        }
        vm.isLoading = false;
        vm.errors = ['Report could not be loaded'];
        vm.onChangePagesCount({pagesCount: 0});
        vm.data = null;
      });
  }

  function modifyData (data) {
    data.forEach(function (obj) {
      obj.jsUniversalSecureSelect = true;
      obj.imgUniversalSecureSelect = true;
      obj.jsConversionSecureSelect = true;
      obj.imgConversionSecureSelect = true;
    });
  }

  vm._snippetAcordeon = 'js_uni';
  vm.switchAcordeon = function(name) {
    vm._snippetAcordeon = name;
  }
  vm.isAcordeonVisible = function(name) {
    return vm._snippetAcordeon == name;
  }
}


