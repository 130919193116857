/*** IMPORTS FROM imports-loader ***/


angular
  .module('adhesiveApp')
  .component(
    'monthlyReportsByAdvPicker', {
      controller: monthlyReportsByAdvPickerCtrl,
      template: require('$views/admin/monthly-reports-by-adv/picker.html').default,
      controllerAs: 'vm',
      bindings: {
        onChange: '&',
        selectedIds: '<'
      }
    }
  );

monthlyReportsByAdvPickerCtrl.$inject = ['AdvertiserService'];
function monthlyReportsByAdvPickerCtrl(AdvertiserService) {
  var vm = this;
  vm.advertisers = [];
  vm.isLoading = false;
  vm.isError = false;

  vm.$onInit = function () {
    getAdvertisers();
  };

  vm.setAdvName = function () {
    vm.onChange({a_ids: vm.selectedIds});
  };

  function getAdvertisers () {
    vm.isError = false;
    vm.isLoading = true;
    vm.advertisers = [];

    AdvertiserService.fetchAdvertisers().then((advertisers) => {
      vm.advertisers = Object.values(advertisers);
      vm.isLoading = false;
    }, (resp) => {
      vm.isLoading = false;
      vm.isError = true;
    });

  }

}

