/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('adminCampaignsCampaignTable', {
    controller: adminCampaignsCampaignTableCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/campaigns/campaign-table.html').default,
    bindings: {
      lineItems: '<',
      campaignFields: '<',
      campaignTypes: '<',
      revenueTypes: '<',
      campaignCategories: '<',
      postApChanged: '&'
    }
  });


function adminCampaignsCampaignTableCtrl () {
  var vm = this;
  vm.campaignSort = 'value';

  vm.toggleCampaignSort = function (fieldId) {
    if(!vm.campaignSort) {
      vm.campaignSort = fieldId;
      return;
    }

    var reverseFlag = vm.campaignSort.charAt(0) === '-';
    var currentFieldId = vm.campaignSort;
    if (reverseFlag) {
      currentFieldId = currentFieldId.substr(1);
    }

    if (fieldId !== currentFieldId) {
      vm.campaignSort = fieldId;
      return;
    }

    if (reverseFlag) {
      vm.campaignSort = fieldId;
    } else {
      vm.campaignSort = '-' + fieldId;
    }
  };
}

