/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'oldReportsBySalesPicker', {
      controller: oldReportsBySalesRepPickerCtrl,
      template: require('$views/admin/reports-by-sales-rep/reports-by-sales-rep-picker.html').default,
      controllerAs: 'vm',
      bindings: {
        onChange: '&',
        source: '@',
        selectedNames: '<'
      }
    }
  );

oldReportsBySalesRepPickerCtrl.$inject = ['$http', 'apiPrefixUrl'];
function oldReportsBySalesRepPickerCtrl($http, apiPrefixUrl) {
  var vm = this;
  vm.repNames = [];
  vm.isLoading = false;
  vm.isError = false;

  vm.$onInit = function () {
    getReps();
  };

  vm.setRepName = function () {
    vm.onChange({repNames: vm.selectedNames});
  };

  function getReps () {
    vm.isError = false;
    vm.isLoading = true;
    vm.repNames = [];
    var params = {};
    var url = apiPrefixUrl + '/reports-by-sales-rep/' + vm.source;

    $http
      .get(url, {params: params})
      .then(function (resp) {
        vm.isLoading = false;
        vm.repNames = resp.data;
      }, function (resp) {
        vm.isLoading = false;
        vm.isError = true;
      });
  }

}

