/*** IMPORTS FROM imports-loader ***/


angular
  .module('adhesiveApp')
  .component('monthlyReportsByAdvCtrl', {
    controller: monthlyReportsByAdvCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/monthly-reports-by-adv/main.html').default
  });

monthlyReportsByAdvCtrl.$inject = ['userServ', '$location', 'BillingService', '$scope', 'ExcelService'];
function monthlyReportsByAdvCtrl(userServ, $location, BillingService, $scope, ExcelService) {
  var vm = this;
  vm.userServ = userServ;
  vm.month = '';
  vm.search = '';
  vm.joinedIds = '';
  vm.isLoading = false;

  vm.$onInit = function () {
    var search = $location.search();
    var a_ids = search.a_ids;
    vm.a_ids = a_ids ? a_ids.split(',') : [];
    vm.joinedIds = a_ids || '';
    vm.search = vm.a_ids ? vm.a_ids[0] : '';
    vm.month = search.month || null;
  };

  vm.filterAdvId = function (a_ids) {
    vm.joinedIds = a_ids.join(',');
    vm.a_ids = a_ids;
    vm.search = a_ids[0] || '';
    $location.search('a_ids', vm.joinedIds);
  };

  vm.setMonth = function(month) {
    $location.search('month', month);
    vm.month = month;
  };

  $scope.onExportToExcel = function() {
    ExcelService.downloadMonthlyReportsByAdv(vm.a_ids, vm.month).then(
      function() {},
      function(error) {
        // TODO: show error
        console.log(error);
      }
    );
  };
}

