/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'creativeReportsAdvertiserPicker', {
      controller: creativeReportsAdvPickerCtrl,
      template: require('$views/admin/creative-reports/advertiser-picker.html').default,
      controllerAs: 'vm',
      bindings: {
        onChange: '&',
        selectedAdvId: '<'
      }
    }
  );

creativeReportsAdvPickerCtrl.$inject = ['$http', 'apiPrefixUrl'];
function creativeReportsAdvPickerCtrl($http, apiPrefixUrl) {
  var vm = this;
  vm.advertisers = null;
  vm.isLoading = false;
  vm.isError = false;

  vm.$onInit = function () {
    getAdvertisers();
  };

  vm.setAdvId = function () {
    vm.onChange({id: vm.selectedAdvId});
  };

  function getAdvertisers () {
    vm.isError = false;
    vm.isLoading = true;
    vm.advertisers = null;

    $http
      .get(apiPrefixUrl + '/pickers/advertisers')
      .then(function (resp) {
        vm.isLoading = false;
        vm.advertisers = resp.data;
        vm.advertisers.unshift({id: null, name: 'Nothing selected'});
        vm.selectedAdvId = null;
      }, function (resp) {
        vm.isLoading = false;
        vm.isError = true;
      });
  }

}

