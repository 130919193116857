/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .controller(
    'DailyBudgetPerDayCtrl',
    ['$scope',
    function ($scope) {
      $scope.WEEKDAY_MAPPER = [
        'monday', 'tuesday', 'wednesday',
        'thursday', 'friday', 'saturday', 'sunday'
      ];

      if($scope.lineItem.daily_budget_per_day === null) {
        var daily_budget = $scope.lineItem.daily_budget;
        $scope.lineItem.daily_budget_per_day = {
          monday: daily_budget,
          tuesday: daily_budget,
          wednesday: daily_budget,
          thursday: daily_budget,
          friday: daily_budget,
          saturday: daily_budget,
          sunday: daily_budget,
          active: false
        };
      }
    }]
  );

