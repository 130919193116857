/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').controller('CampaignsCtrl', CampaignsCtrl);
CampaignsCtrl.$inject = [
  '$scope', '$routeParams', '$http', 'apiPrefixUrl', '$q', 'PRICING_MODELS', 'utils',
  '$timeout'
];

function CampaignsCtrl(
      $scope, $routeParams, $http, apiPrefixUrl, $q, PRICING_MODELS, utils, $timeout) {
    var campaignsUrl = (
      apiPrefixUrl + '/appnexus/campaigns/advertisers/' + $routeParams.userid
    );

    $scope.haveValue = utils.haveValue;
    $scope.msg = null;
    $scope.hook = {};

    $scope.WEEKDAY_MAPPER = [
      'sunday', 'monday', 'tuesday', 'wednesday',
      'thursday', 'friday', 'saturday'
    ];

    $scope.campaignFields = [
        {field: 'postAp', label: 'Post AP', visible: true, notfilter: false},
        {field: 'revenueType', label: 'Revenue Type', visible: true, notfilter: false},
        {field: 'name', label: 'Campaign group', visible: true, notfilter: true, id: 'value'},
        {field: 'type', label: 'Type', visible: true, notfilter: false},
        {field: 'category', label: 'Category', visible: true, notfilter: false},
        {field: 'revenue', label: 'Booked revenue', visible: true, notfilter: false},
        {field: 'dailyBudget', label: 'Daily budget', visible: true, notfilter: false},
        {field: 'campaignName', label: 'Campaign', visible: true, notfilter: false, style: {'min-width': '110px'}},
        {field: 'bid', label: 'Bid', visible: true, notfilter: false},
        {field: 'frequency', label: 'Freq.', visible: true, notfilter: false},
        {field: 'daysSince', label: 'Days since', visible: true, notfilter: false},
        {field: 'impCap', label: 'Imp Cap', visible: true, notfilter: false},
        {field: 'impsYesterday', label: 'Imps Yest.', visible: true, notfilter: false},
        {field: 'percentBudget', label: '% Of Budget', visible: true, notfilter: false},
        {field: 'dayParting', label: 'Day parting', visible: true, notfilter: false},
        {field: 'learnBudget', label: 'Learn budget', visible: true, notfilter: false},
        {field: 'totalMediaCost', label: 'Total Media Cost', visible: true, notfilter: false},
        {field: 'dailyImpCap', label: 'Daily Imp Cap', visible: true, notfilter: false},
        {field: 'cpa7days', label: 'CPA (7d)', visible: true, notfilter: false},
        {field: 'cog7days', label: 'COG% (7d)', visible: true, notfilter: false},
        {field: 'roas7days', label: 'ROAS (7d)', visible: true, notfilter: false},
        {field: 'cpaYesterday', label: 'CPA Yest.', visible: true, notfilter: false},
        {field: 'cogYesterday', label: 'COG% Yest.', visible: true, notfilter: false},
        {field: 'creativesCount', label: 'Creatives Count', visible: true, notfilter: false},
        {field: 'spendYesterday', label: 'Rev Yest.', visible: true, notfilter: false, id: 'spend_yesterday'},
        {field: 'logs', label: 'Logs', visible: true, notfilter: false}
    ];

    $scope.fieldIds = {};
    $scope.campaignFields.forEach(function (field, index) {
      $scope.fieldIds[field.field] = index;
    });

    $scope.getPricingModelId = function(label){
        var r;
        angular.forEach(PRICING_MODELS, function(v, k){
            if(label.toLowerCase() == v.toLowerCase()){
                r = k;
            }
        });
        return r;
    };

    $scope.PRICING_MODEL_CPM_ID = $scope.getPricingModelId('cpm');
    $scope.PRICING_MODEL_CPA_ID = $scope.getPricingModelId('cpa');

    $scope.send = false;
    $scope.loading = true;
    $scope.msgs = [];

    $scope.isPricingModel = function(modelId){
        if($scope.data && $scope.data.advertiser_profile
            && $scope.data.advertiser_profile.advertiser_pricing_model){
            return $scope.data.advertiser_profile.advertiser_pricing_model
                == modelId;
        }
        return false;
    };

    $scope.campaignType = function(id){
        if(!$scope.campaign_types){
            return;
        }

        var type;

        angular.forEach($scope.campaign_types, function(v){
            if(v.id == id){
                type = v;
            }
        });

        return type;
    };

    var catgsPromise = $http({method: 'GET', url: apiPrefixUrl + '/appnexus/campaigns/categories', cache: true})
       .then(function(resp){
            $scope.campaign_categories = resp.data;
       }, function(){
            $scope.msgs.push(["Can't categories data", 'alert-error']);
       });

    var typesPromise = $http({method: 'GET', url: apiPrefixUrl + '/appnexus/campaigns/types', cache: true})
        .then(function(resp){
            $scope.campaign_types = resp.data;
        }, function(){
            $scope.msgs.push(["Can't get campaign types", 'alert-danger']);
        });

    $scope.revenue_types = [
      {value: "CPA", id: "CPA"},
      {value: "CPM", id: "CPM"},
    ];

    function updateData (data) {
        var advertiser_profile = data && data.advertiser_profile;
        if (angular.isUndefined(advertiser_profile)) { return; }

        if (advertiser_profile.business_relationship === 'agency') {
            advertiser_profile.business_relationship = true;
        } else if (advertiser_profile.business_relationship === 'direct') {
            advertiser_profile.business_relationship = false;
        } else {
            advertiser_profile.business_relationship = undefined;
        }

        advertiser_profile.line_items.forEach(function (lineItem) {
          lineItem.campaigns.sort(function (a, b) {
            if (a.is_remainder) { return +1; }
            if (b.is_remainder) { return -1; }
            return a.id - b.id;
          });
        });
    }

    var campsPromise = $http.get(campaignsUrl)
       .then(function(resp){
            var data = resp.data;
            updateData(data);
            $scope.data = data;
            $scope.$broadcast('parentControllerLoaded');
       }, function(){
            $scope.msgs.push(["Can't get campaigns data", 'alert-danger']);
       });


    $scope.isPositiveNumericString = function (value) {
      value = parseFloat(value);
      return isFinite(value) && value >= 0.01;
    };

    function valid_daily_budgets () {
      var line_items_has_err = false;
      var line_items = $scope.data.advertiser_profile.line_items;
      line_items.forEach(function (line_item) {
        if (line_item.source_data_type === 1) {  // ALI: ADHES-2222
          return;
        }
        var line_item_has_err  = false;
        var daily_budget_per_day = line_item.daily_budget_per_day;
        if (daily_budget_per_day && daily_budget_per_day.active) {
          $scope.WEEKDAY_MAPPER.forEach(function (day) {
            var daily_budget = daily_budget_per_day[day];
            if (!$scope.isPositiveNumericString(daily_budget)) {
              line_item_has_err = true;
            }
          });
        } else {
          if (
            line_item.post_ap &&
            !$scope.isPositiveNumericString(line_item.daily_budget)
          ) {
            line_item_has_err = true;
          }
        }
        if (line_item_has_err) {
          line_item._errors = line_item._errors || {};
          line_item._errors['daily budget'] = ['is not a positive number.'];
          line_items_has_err = true;
        }
      });
      return !line_items_has_err;
    }

    $scope.submit = function(){
        $scope.msgs = [];
        $scope.send = true;
        clearErrorsFromData();
        if (!valid_daily_budgets()){
          $scope.msgs.push([
            "Daily budget field was not properly validated",
            'alert-danger'
          ]);
          return false;
        }

        if($scope.hook.form.$invalid){
          $scope.msgs.push(["Required fields are empty or given not correct numbers", 'alert-danger'])
          return false;
        }
        var advertiser_profile = $scope.data.advertiser_profile;
        var line_items = angular.copy(advertiser_profile.line_items);

        function convertToIntOrNull (obj, key) {
          var value = Number('+' + obj[key]); // force convert to number
          obj[key] = isNaN(value) ? null : value;
        }

        angular.forEach(line_items, function (line_item) {
          var daily_budget_per_day = line_item.daily_budget_per_day;
          if (daily_budget_per_day && !daily_budget_per_day.active) {
            line_item.daily_budget_per_day = null;
          }
          line_item.campaigns = line_item.campaigns.filter(function(value) {
            return value["is_archived"] != true;
          });
          line_item.campaigns.forEach(function (campaign) {
            if (line_item.source_data_type === 0) {  // ALI: ADHES-2222
              convertToIntOrNull(campaign, 'daily_budget_imps');
            }
            convertToIntOrNull(campaign, 'daily_budget_percent');
          });
        });

        var data = {
            advertiser_profile: {
                advertiser_pricing_model: advertiser_profile.advertiser_pricing_model,
                line_items: line_items,
                low_balance_alert: advertiser_profile.low_balance_alert,
                is_in_drop_goal_email: advertiser_profile.is_in_drop_goal_email,
                roas_goal: advertiser_profile.roas_goal,
                hivewyre_lift_goal: advertiser_profile.hivewyre_lift_goal,
                hivewire_lift_percentage: advertiser_profile.hivewire_lift_percentage,
                monthly_budget: advertiser_profile.monthly_budget,
                show_conversion_report: advertiser_profile.show_conversion_report,
            }
        };

        data.advertiser_profile.advertiser_pace = advertiser_profile.advertiser_pace;
        data.advertiser_profile.advertiser_cpa_goal = advertiser_profile.advertiser_cpa_goal;
        data.advertiser_profile.advertiser_daily_budget = advertiser_profile.advertiser_daily_budget;

        data.advertiser_profile.advertiser_is_contributing = advertiser_profile.advertiser_is_contributing;
        if(advertiser_profile.business_relationship) {
            data.advertiser_profile.business_relationship = 'agency';
        } else {
            data.advertiser_profile.business_relationship = 'direct';
        }

        $scope.msgs = [["Saving data...", 'alert-info']];
        $scope.saving = true;
        $http
            .patch(campaignsUrl, data)
            .then(function (resp) {
                $scope.saving = false;
                var data = resp.data;
                $scope.msgs = [["Campaigns data saved", 'alert-success']];
                updateData(data);
                $scope.data = data;
            }, function (resp) {
              $scope.saving = false;
              var data = resp.data || {};
              setErrorsToData(data);

              var errorMsg = data.msg || "Can't save campaigns data";
              $scope.msgs = [[errorMsg, 'alert-danger']];
            });
    };

    function setErrorsToData (errors) {
      var advertiserErrors = errors.advertiser_profile || {};
      var lineItemErrors = advertiserErrors.line_items;
      if (!lineItemErrors) {
        return;
      }

      $scope.data.advertiser_profile.line_items.forEach(function (lineItem, lineItemIndex) {
        var lineItemError = lineItemErrors[lineItemIndex] || {};

        // sometimes campaigns error has only string, not objects
        // we must check this
        var campaignsError = lineItemError.campaigns || [];
        var hasCampaignErrorMessages = campaignsError.some(function (msg) {
          return typeof msg === 'string';
        });

        if (!hasCampaignErrorMessages) {
          // because when campaigns object has nested object with error
          // to single campaigns, then is not possible to show as normal
          // message to line item errors.
          delete lineItemError.campaigns;
          lineItem.campaigns.forEach(function (campaign, campaingIndex) {
            var error = campaignsError[campaingIndex];
            if (!objectIsEmpty(error)) {
              campaign._errors = error;
            }
          });
        }

        if (!objectIsEmpty(lineItemError)) {
          lineItem._errors = lineItemError;
        }
      });
    }

    function objectIsEmpty (obj) {
      return Object.keys(obj || {}).length === 0;
    }

    function clearErrorsFromData () {
      $scope.data.advertiser_profile.line_items.forEach(function (lineItem) {
        delete lineItem._errors;
        lineItem.campaigns.forEach(function (campaign) {
          delete campaign._errors;
        });
      });
    }

    $scope.loginAs = function () {
        window.open('#/login/' + $routeParams.userid);
    };

    $scope.postApChanged = function(postAp) {
        // ADHES-1425
        if(!postAp) {
            $scope.data.advertiser_profile.low_balance_alert = true;
        }
    };


    $q.all([catgsPromise, typesPromise, campsPromise])
        .then(function(){
            $scope.loading = false;
        })
        .catch(function(){
            $scope.loading = false;
        });
    $scope.$on('msg:campaigns', function(e, msg){
        $scope.msg = msg;
    });
    $scope.$watch('data.advertiser_profile.advertiser_pricing_model', function(){
      function setVisible(name, value) {
        var fieldIndex = $scope.fieldIds[name];
        var field = $scope.campaignFields[fieldIndex];
        field.visible = value;
        field.notfilter = !value;
      }

      setVisible('revenue', true);
      setVisible('dailyBudget', true);
      setVisible('totalMediaCost', true);
      setVisible('dayParting', true);
    });


    $scope.refreshLoading = false;
    $scope.refreshError = null;
    $scope.refreshSuccess = false;
    $scope.refreshAdvertiser = function () {
      $scope.refreshLoading = true;
      $scope.refreshSuccess = false;
      $scope.refreshError = null;
      $http
        .post(campaignsUrl + '/refresh', {})
        .then(
          function (resp) {
            $scope.refreshLoading = false;
            $scope.refreshSuccess = true;
            setRefreshInterval();
          }, function (resp) {
            $scope.refreshLoading = false;
            var data = resp.data || {};
            $scope.refreshError = data.msg || "Refresh failed. Please try again";
            setRefreshInterval();
          }
        );
    };

    function setRefreshInterval() {
        if ($scope.refreshTimeout) {
          $timeout.cancel($scope.refreshTimeout);
        }
        $scope.refreshTimeout = $timeout(function () {
          $scope.refreshSuccess = false;
          $scope.refreshError = null;
        }, 5000);
    }

}

