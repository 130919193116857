/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').controller(
    'AdminCtrl', ['$scope', 'UserModel', '$location', 'USER_TYPES', 'apiPrefixUrl', 'utils',
    'userServ', 'PRICING_MODELS', 'time', '$window', '$routeParams',
        function (
          $scope ,UserModel ,$location , USER_TYPES, apiPrefixUrl, utils,
          userServ, PRICING_MODELS, time, $window, $routeParams) {

        var i, MAX_VISIBLE_COLUMNS = 13;

        var initDate = function(route_key) {
            var date;

            var rawDate = $routeParams[route_key];
            if (rawDate) {
              date = time.parseBackendRawDateToDate(rawDate);
            }

            if(!date || date == 'Invalid Date') {
                date = time.getUtcYesterdayDate();
            }
            return date;
        };

        $scope.userServ = userServ;
        $scope.pagesCount = 0;
        $scope.pageLimit = 20;
        $scope.fromDate = initDate('from_date');
        $scope.toDate = initDate('to_date');

        $scope.userFilter = $scope.userServ.userFilter || {};
        $scope.PRICING_MODELS = PRICING_MODELS;
        $scope.haveValue = utils.haveValue;

        $scope.COLUMNS_VERSION = '13'; // please increase when default columns was changed
        $scope.columns = [
            {name: 'type', label: 'Type', sort: 'permission_level', visible: true, notspanned: true},
            {name: 'adv_status', label: 'Status in appnexus', sort: 'advertiser_profile__advertiser_status', visible: true},
            {name: 'line_item_active', label: 'Line Items', sort: 'advertiser_profile__line_item_is_active', visible: true},
            {name: 'adv_id', label: 'Adv ID', sort: 'advertiser_profile__advertiser_id', visible: true},
            {name: 'username', label: 'Name', sort: 'advertiser_profile__advertiser_username', visible: true},
            {name: 'options', label: 'Options', visible: true},
            {name: 'actions', label: 'Actions', sort: false, visible: true, actions_header: true},
            {name: 'learn_budget', label: 'Learn budget', sort: 'advertiser_profile__learn_budget', visible: false},
            {name: 'cpa_cpm', label: 'CPA/CPM', sort: 'advertiser_profile__advertiser_pricing_model', visible: true},
            {name: 'cpaoff', label: 'CPA% Off', sort: null, /* sort: 'advertiser_profile__cpaoff_7days', */ visible: true},
            {name: 'cpagoal', label: 'CPA GOAL', sort: null, /* sort: 'advertiser_profile__advertiser_cpa_goal', */ visible: true},
            {name: 'ecpa', label: 'ECPA', sort: null, /* sort: 'advertiser_profile__cpa_7days', */ visible: true},
            {name: 'cog', label: 'COG%', sort: null, /* sort: 'advertiser_profile__cog_7days', */ visible: true},
            {name: 'spend', label: 'Rev', sort: null, /* sort: 'advertiser_profile__spend_7days', */ visible: true},
            {name: 'roas', label: 'ROAS', sort: null, /* sort: 'advertiser_profile__roas_7days', */ visible: true},
            {name: 'roas_goal', label: 'ROAS Goal', sort: 'advertiser_profile__roas_goal', visible: true},
            {name: 'imp_cap', label: 'Imp Cap', sort: 'advertiser_profile__imp_cap', visible: false},
            {name: 'credit_limit', label: 'Credit Limit', sort: 'billing_profile__credit_limit', visible: true},
            {name: 'balance', label: 'Balance owned', sort: 'billing_profile__balance', visible: true},
            {name: 'spend_left', label: 'Spend Left', sort: 'spend_left', visible: true},
            {name: 'funds', label: 'Funds', sort: null, visible: true},
            {name: 'charge_status', label: 'Charge status', sort: null, visible: true},
            {name: 'segments', label: 'Segments', sort: null, visible: false},
            {name: 'imps', label: 'Impressions', sort: null, visible: false},
            {name: 'adv_revenue', label: 'Advertiser revenue', sort: null, visible: false},
            {name: 'avg_order_value', label: 'Average order value', sort: null, visible: false},
            {name: 'site_conversion_rate', label: 'Site conversion rate', sort: null, visible: false},
            {name: 'hivewyre_lift_yesterday_percentage', label: 'HW Lift', sort: null, visible: false},
            {name: 'data_share', label: 'Data share', sort: null, visible: false},
            {name: 'clicks', label: 'Clicks', sort: null, visible: false},
            {name: 'ctr', label: 'CTR', sort: null, visible: false},
            {name: 'average_cpc', label: 'Average CPC', sort: null, visible: false},
            {name: 'avg_daily_uniques_visits', label: 'Average Daily Uniques Visits', sort: null, visible: false}
        ];
        $scope.col_nums = {};
        angular.forEach($scope.columns, function (column, index) {
            $scope.col_nums[column.name] = index;
        });
        $scope.types = {};
        for(i = 0; i < USER_TYPES.length; i += 1) {
            $scope.types[USER_TYPES[i].value] = USER_TYPES[i].label;
        }

        $scope.typesNames = [[], []];
        USER_TYPES.forEach(function(type, i){
            var sid = type.label.toLowerCase().replace(/\s/g, '');
            $scope.typesNames[i%2].push({sid: sid, name:type.label});
        });

        $scope.selectedStatuses = $scope.userServ.selectedStatuses || {};

        $scope.appnexusStatuses = [
            {label: 'all'},
            {label: 'active', value: 1},
            {label: 'inactive', value: '2,3,4'},
            {label: 'Registered', value: 4}
        ];

        $scope.lineItemStatuses = [
            {label: 'all'},
            {label: 'active', value: 1},
            {label: 'inactive', value: 0}
        ];

        $scope.bussinesStatuses = [
            {label: 'all'},
            {label: 'agency', value: 'agency'},
            {label: 'direct', value: 'direct'}
        ];

        $scope.search = $location.search()['name'] || '';
        $scope.searchUsers = function(clear) {
            if(clear) {
                $scope.search = '';
                $location.search('name', null);
            } else {
                $location.search('name', $scope.search);
            }
            $location.search('page', null);
            $scope.loadUsers();
        };

        $scope.updateDateRangeIfValid = function() {
          if($scope.setdateform.$valid) {
            $location.search('from_date', time.dateToString($scope.fromDate));
            $location.search('to_date', time.dateToString($scope.toDate));
          }
        };

        $scope.columnsMenu = [[], []];
        $scope.columns.slice(1).forEach(function(col, i){
            $scope.columnsMenu[i%2].push(col);
        });

        $scope.initOrder = function() {
            var order = $location.search().order,
                asc, column, i, length;

            if(angular.isString(order)) {
                if(order[0] == '-') {
                    asc = false;
                    order = order.slice(1);
                }
                else {
                    asc = true;
                }

                for(i = 0, length = $scope.columns.length; i < length; i += 1) {
                    if($scope.columns[i].sort == order) {
                        column = $scope.columns[i];
                        break;
                    }
                }

            }

            if(angular.isUndefined(asc) || angular.isUndefined(column)) {
                asc = false;
                column = $scope.columns[$scope.col_nums.learn_budget];
            }

            $scope.order_by = {
                column: column,
                asc: asc
            };
        };

        $scope.initOrder();

        $scope.isOrdered = function(column) {
            return $scope.order_by.column == column;
        };

        $scope.setOrder = function(column) {
            if(column.sort) {
                if(column == $scope.order_by.column) {
                    $scope.order_by.asc = !$scope.order_by.asc;
                }
                else {
                    $scope.order_by.asc = true;
                }
                $scope.order_by.column = column;
                $location.search('page', 1);
                $location.search('order', $scope.getOrder());
            }
        };

        $scope.getOrder = function() {
            return ($scope.order_by.asc?'':'-') + $scope.order_by.column.sort;
        };

        $scope.currentPage = function(){
            return Number($location.search().page) || 1;
        };

        $scope.nextPage = function(){
            if($scope.currentPage() < $scope.pagesCount){
                $location.search('page', $scope.currentPage() + 1);
            }
        };

        $scope.prevPage = function(){
            if($scope.currentPage() > 1){
                $location.search('page', $scope.currentPage() - 1);
            }
        };

        $scope.setPage = function() {
            if($scope.pagination.$valid) {
                $scope.page = Math.min(Math.max($scope.page, 1), $scope.pagesCount);
                $location.search('page', $scope.page);
            }
        };

        $scope.getUserTypes = function(){
            var values = [];

            USER_TYPES.forEach(function(pm){
                var label = pm.label.toLowerCase().replace(/\s/g, '');
                if($scope.userFilter[label]){
                    values.push(pm.value);
                }
            });

            if(values.length){
                return values;
            }
            return null;
        };

        $scope.getUsersListQuery = function(){
            var i,
                userTypes = $scope.getUserTypes(),
                query = {
                    page: $scope.currentPage(),
                    page_size: $scope.pageLimit,
                    ordering: $scope.getOrder(),
                    from_date: time.dateToString($scope.fromDate),
                    to_date: time.dateToString($scope.toDate),
                };

            if($scope.search !== '') {
                query.search = $scope.search;
            }

            if(userTypes){
                query.permission_level = userTypes.join(',');
            }

            if(angular.isDefined($scope.selectedStatuses.appnexus)) {
                query.advertiser_profile__advertiser_status = $scope.selectedStatuses.appnexus;
            }

            if(angular.isDefined($scope.selectedStatuses.lineItem)) {
                query.advertiser_profile__line_item_is_active = $scope.selectedStatuses.lineItem;
            }

            if(angular.isDefined($scope.selectedStatuses.bussines)) {
                query.advertiser_profile__business_relationship = $scope.selectedStatuses.bussines;
            }

            if(userTypes) {
                if(userTypes.indexOf(4) !== -1) {
                    if(query.advertiser_profile__advertiser_status) {
                        if(query.advertiser_profile__advertiser_status === '2,3,4') {
                            query.advertiser_profile__advertiser_status = '2,3';
                        }
                    }
                    else {
                        query.advertiser_profile__advertiser_status = '1,2,3';
                    }
                }
            }

            if(query.advertiser_profile__advertiser_status === 4) {
                query.permission_level = 4;
                query.advertiser_id = 0;
                delete query.advertiser_profile__advertiser_status;
            }

            return query;
        };

        $scope.getExportToExcelURL = function(value){
            var url = apiPrefixUrl + '/appnexus/advertisers';
            if (!angular.isUndefined(value)) {
              url += '/' + value;
            }
            url += '/export_to_excel';
            return utils.getUrlWithSearchParams(url, $scope.getUsersListQuery());
        };

        $scope.loadUsers = function(){
            $scope.errors = [];
            $scope.usersList = [];
            $scope.loading = true;
            $scope.msg = null;
            $scope.initOrder();
            $scope.page = $scope.currentPage();

            UserModel.get($scope.getUsersListQuery(),
                function(res){
                    $scope.usersList = res.results;
                    $scope.pagesCount = Math.ceil(res.count / $scope.pageLimit);
                    $scope.loading = false;
                }, function(){
                    $scope.errors = ['Users data could not be loaded'];
                    $scope.loading = false;
                });
        };

        $scope.loginAs = function(userId){
            window.open('#/login/' + userId);
        };

        $scope.hideColumns = function(number){
            angular.forEach($scope.columns, function(v, k){
                // except first column
                if(k == 0){
                    return false;
                }
                if(number > 0 && v.visible){
                    v.visible = false;
                    number--;
                }
            });
        };

        var loadDataInNextWatch = true;

        $scope.$watch(
            '[userFilter, selectedStatuses]',
            function(n, o) {
                if($scope.selectedStatuses.appnexus === 4) {
                    if(o[1].appnexus !== n[1].appnexus) {
                        $scope.userFilter = {};
                        if(Object.keys(n[0]).length !== 0) {
                            loadDataInNextWatch = false;
                        }
                    }
                    else {
                        if(Object.keys($scope.userFilter).length > 0) {
                            $scope.selectedStatuses.appnexus = undefined;
                            if(n[1] !== undefined) {
                                loadDataInNextWatch = false
                            }
                        }
                    }
                }
                if(loadDataInNextWatch) {
                    // if old version is different than new then set pagination to first
                    // because $scope.$watch is broken :(
                    if($scope.currentPage() !== 1 && !angular.equals(n, o)) {
                        $location.search('page', 1);
                    } else {
                        $scope.loadUsers();
                    }
                } else {
                    loadDataInNextWatch = true;
                }
                $scope.userServ.selectedStatuses = $scope.selectedStatuses;
                $scope.userServ.userFilter = $scope.userFilter;
            },
            true
        );

        // Storing visibility of the columns
        $scope.$watch(function(){
            return $scope.columns.map(function(v){
                return v.visible;
            });
        }, function(nv, ov){
            // Initial run
            if(angular.equals(nv, ov)){
                var stored = angular.fromJson($window.localStorage.adminColumns);
                var stored_version = $window.localStorage.adminColumnsVer;
                if(stored && stored_version === $scope.COLUMNS_VERSION && angular.isArray(stored)){
                    nv = stored;
                    $scope.columns.forEach(function(v, i){
                        v.visible = nv[i];
                    });
                }

            }

            // Update localStorage
            $window.localStorage.adminColumnsVer = $scope.COLUMNS_VERSION;
            $window.localStorage.adminColumns = angular.toJson(nv);
        }, true);
        $scope.$on('$routeUpdate', $scope.loadUsers);
        $scope.$on('user-list-reload', $scope.loadUsers);
        $scope.$on('msg:admin', function(e, msg){
            $scope.msg = msg;
        });
        $scope.$on('popover-opened', function(){
            $scope.msg = null;
        });
    }]);

