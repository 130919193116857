/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
  .controller('AppnexusHivewyreLiftCtrl', ['$scope',
  function ($scope) {
    // Make list label => key of hivewyre lift metrics

    var metrics = $scope.user.combined_reports,
      // key wich will be retrived from the advertiser profile: labels
      VALUES = [
        { key: 'yesterday_hivewyre_lift_percentange', label:'Yesterday'},
        { key: 'last_7days_hivewyre_lift_percentange', label:'Last 7 days'},
        { key: 'last_30days_hivewyre_lift_percentange', label:'Last 30 days'},
        { key: 'mtdy_hivewyre_lift_percentange', label:'Month to Day'},
      ];

      // We can not proceede when the adrvertiser profile is missing
      if(!metrics) return;

      $scope.hwl_values = [];
      angular.forEach(VALUES, function(obj) {
        $scope.hwl_values.push({
          label: obj.label,
          value:  typeof metrics[obj.key] == 'number' ? metrics[obj.key].toFixed(2) +'%' : '-'
        });
      });
  }]);

