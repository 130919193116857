/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('creativeReportsTable', {
    controller: creativeReportsTableCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/creative-reports/table.html').default,
    bindings: {
      filterAdvertiser: '<',
      filterCreative: '<',
      filterHours: '<',
      groupBy: '<',
      startDate: '<',
      endDate: '<',
      page: '<',
      isLoading: '=',
      onChangePagesCount: '&',
      onChangeSort: '&'
    }
  });


creativeReportsTableCtrl.$inject = ['apiPrefixUrl', '$http', 'time', '$q'];
function creativeReportsTableCtrl(apiPrefixUrl, $http, time, $q) {
  var vm = this;

  vm.sort = '-revenue';
  vm.isLoading = false;
  vm.canceler = null;

  vm.$onInit = function () {
    clearData();
  };

  vm.$onChanges = function () {
    if (vm.startDate && vm.endDate) {
      loadData();
    }
  };

  vm.changeSort = function (value) {
    if (vm.sort === '-' + value) {
      vm.sort = value;
    } else {
      vm.sort = '-' + value;
    }
    vm.onChangeSort({sort: vm.sort});
    if (vm.page === 1) {
      loadData();
    }
  };

  vm.columns = [
    {field: 'name', title: 'Creative name', sortable: 'creative__name', show: true},
    {field: 'image', title: 'Image', show: true},
    {field: 'size', title: 'Size', show: true},
    {field: 'imps', title: 'Imps', sortable: 'sum_imps', show: true},
    {field: 'clicks', title: 'Clicks', sortable: 'sum_clicks', show: true},
    {field: 'ctr', title: 'CTR%', sortable: 'ctr', show: true},
    {field: 'total_conversions', title: 'Total convs', sortable: 'sum_total_conversions', show: true},
    {field: 'conversions', title: 'convs', sortable: 'sum_conversions', show: true},
    {field: 'visitor_conversions', title: 'Visit convs', sortable: 'sum_visitor_conversions', show: true},
    {field: 'revenue', title: 'Revenue', sortable: 'revenue', show: true},
    {field: 'conversion_revenue', title: 'Convs Revenue', sortable: 'conversion_revenue', show: true},
    {field: 'post_click_conversion_rate', title: 'Post Click Conv Rate ', sortable: 'post_click_conversion_rate', show: true},
  ];

  function clearData () {
    vm.data = null;
    vm.summary = null;
    vm.errors = null;
  }

  function loadData () {
    clearData();
    vm.isLoading = true;
    if (vm.canceler) {
      vm.canceler.resolve();
    }
    vm.canceler = $q.defer();

    var query = {
      ordering: vm.sort,
      page: vm.page,
      group_by: vm.groupBy,
      from_date: time.getBackendDate(vm.startDate),
      to_date: time.getBackendDate(vm.endDate)
    };

    if (vm.filterAdvertiser) {
      query.advertiser = vm.filterAdvertiser;
    }

    if (vm.filterCreative) {
      query.creative = vm.filterCreative;
    }

    if (vm.filterHours) {
      query.hour = vm.filterHours.join(',');
    }

    vm.request = $http
      .get(apiPrefixUrl + '/creative-reports/', {
        params: query,
        timeout: vm.canceler.promise
      })
      .then(function (resp) {
        vm.isLoading = false;
        vm.onChangePagesCount({pagesCount: resp.data.count});
        vm.data = resp.data.records || [];
        if (vm.data.length > 0) {
          vm.summary = resp.data.summary;
        } else {
          vm.summary = null;
        }
      }, function (resp) {
        if (resp.status < 0) {
          return;
        }
        vm.isLoading = false;
        vm.errors = ['Report could not be loaded'];
        vm.onChangePagesCount({pagesCount: 0});
        vm.summary = null;
        vm.data = null;
      });
  }
}


