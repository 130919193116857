/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('missedCapReportCtrl', {
    controller: missedCapReportCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/missed-cap-report/main.html').default
  });


missedCapReportCtrl.$inject = ['apiPrefixUrl', 'userServ'];
function missedCapReportCtrl(apiPrefixUrl, userServ) {
  var vm = this;

  vm.selectedSearch = '';
  vm.percentDiffFilterInput = 10;
  vm.selectedPercentDiffFilter = vm.percentDiffFilterInput;
  vm.searchInput = '';
  vm.userServ = userServ;
  vm.isLoading = false;

  vm.filterSalesOrManager = function () {
    vm.selectedSearch = vm.searchInput;
  };

  vm.filterLowMissedOpportunity = function() {
    vm.selectedPercentDiffFilter = vm.percentDiffFilterInput;
  };

  vm.clearSearch = function() {
    vm.selectedSearch = '';
    vm.searchInput = '';
  };

  vm.getExportToExcelURL = function () {
    var url = apiPrefixUrl + '/missed-cap/report-excel';
    if (vm.selectedSearch) {
      url += '?search=' + vm.selectedSearch;
    }
    return url;
  };

  vm.columns = [
    {field: 'salesperson', title: 'Sales Rep', sortable: 'advertiser_profile__salesperson', show: true},
    {field: 'account_manager', sortable: 'advertiser_profile__account_manager', title: 'Account Rep', show: true},
    {field: 'advertiser_username', title: 'Advertiser', sortable:'advertiser_profile__advertiser_username', show: true},
    {field: 'value', title: 'Line Item', sortable: 'line_item_obj__value', show: true},
    {field: 'used_budget', title: 'Daily Cap', sortable: 'used_budget', show: true},
    {field: 'spend', title: 'Yesterday Spend', sortable: 'spend', show: true},
    {field: 'missed_opportunity', title: 'Missed Opportunity', sortable: 'missed_opportunity', show: true}
  ];
}


