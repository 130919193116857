/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'forecastingSummaryTable', {
      controller: forecastingSummaryTableCtrl,
      template: require('$views/admin/forecasting/forecasting-summary-table.html').default,
      controllerAs: 'vm',
      bindings: {
        summary: '<',
      }
    }
  );

forecastingSummaryTableCtrl.$inject = ['NgTableParams'];
function forecastingSummaryTableCtrl(NgTableParams) {
  var vm = this;
  vm.columns = [
    {field: 'month_complete', 'title': 'Month Complete'},
    {field: 'days_remaining', 'title': 'Days Remaining'},
    {field: 'daily_pacing', 'title': 'Daily Pacing'},
    {field: 'daily_ats_pacing', 'title': 'ATS Pacing (Daily)'},
    {field: 'spend_pacing_per_day', 'title': 'Spend Needed (Daily)'},
    {field: 'spend_yesterday', 'title': 'Spend Daily (Yesterday)'}
  ];
  vm.tableParams = new NgTableParams(
    {},
    {
      getData: function() {return [vm.summary];},
      counts: []
    }
  );

  vm.$onChanges = function (changesObj) {
    var summary = changesObj.summary;
    if (summary && !summary.isFirstChange()) {
      vm.tableParams.reload();
    }
  };
}

