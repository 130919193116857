/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .controller('RevenueOldCtrl', RevenueOldCtrl);

  RevenueOldCtrl.$inject = [
  '$scope', '$filter', '$http', 'apiPrefixUrl', 'userServ', 'time', '$timeout'
];
function RevenueOldCtrl (
    $scope, $filter, $http, apiPrefixUrl, userServ, time, $timeout) {
        var orderBy = $filter('orderBy');
        $scope.lastSortedBy = '';
        $scope.isSortedReverse = true;
        $scope.userServ = userServ;
        $scope.loading = true;
        $scope.summary = null;
        $scope.dateRangeId = 'today';
        $scope.additonalDateRangeIds = [
          {key: 'today', value: 'Today'}
        ];

        $scope.sortRevenue = function(category) {
            if($scope.lastSortedBy === category) {
              $scope.isSortedReverse = !$scope.isSortedReverse;
            } else {
              $scope.isSortedReverse = (category !== 'name');
            }
            $scope.revenueList = orderBy($scope.revenueList, category, $scope.isSortedReverse);
            $scope.lastSortedBy = category;
        };

        $scope.getTableData = function(clear_picker, startDate, endDate) {
            if (clear_picker) {
              $scope.dateRangeId = '';
            }
            $scope.startDate = startDate;
            $scope.endDate = endDate;
            var params = {
              from_date: time.getBackendDate($scope.startDate),
              to_date: time.getBackendDate($scope.endDate)
            };
            $scope.summary = null;
            $http
              .get(apiPrefixUrl + '/appnexus/report_revenue', {params: params})
              .then(function(resp) {
                var data = resp.data.records;
                $scope.loading = false;
                $scope.summary = resp.data.summary;
                $scope.revenueList = data;
                var revenueNumer = $scope.revenueList.length;
                for(var i = 0; i < revenueNumer; i++) {
                    $scope.revenueList[i].showAdvertisers = false;
                }
                $scope.lastSortedBy = '';
                $scope.sortRevenue('revenue');
            });
        };


        $scope.chartData = {};
        $scope.chartData.options = {
            yaxis : {
                show: true,
                tickFormatter: function (v) {
                    return $filter('currency')(v);
                }
            },
            xaxis : {
                show:  true,
                autoscaleMargin: 0.05
            },
            bars: {
                align: 'center',
                barWidth: 0.5
            },
            grid : {
                show : true,
                margin: {
                    top: 20,
                    left: 10,
                    bottom: 0,
                    right: 30
                },
                borderWidth: 0,
                labelMargin: 10,
                color: '#000000',
                hoverable: true
            },
            series: {
                bars: {
                    show: true
                }
            },
            color: '#ffc200',
            shadowSize: 0,
            tooltip: {
                show: true,
                cssClass: 'flot-tooltip-campaigns'
            }
        };
        $scope.getModalData = function(data) {
            $scope.chartData.data = [{}];
            $scope.chartData.data[0].data = [];
            $scope.chartData.options.xaxis.ticks = [];
            $scope.chartData.data[0].label = 'Revenue Report';
            for(var i = 0; i < data.length; i++) {
                $scope.chartData.data[0].data.push([i, data[i].revenue]);
                $scope.chartData.options.xaxis.ticks.push([i, data[i].name]);
            }
            if(data.length === 1) {
                $scope.chartData.options.xaxis.autoscaleMargin = 0.5;
            } else {
                $scope.chartData.options.xaxis.autoscaleMargin = 0.05;
            }
            $scope.displayModal = true;
        };

        $scope.refreshDateRangeId = 'today';
        $scope.refreshDateRangeIds = [
          {key: 'today', value: 'Today'},
          {key: 'yesterday', value: 'Yesterday'},
          {key: 'last_7_days', value: 'Last 7 days'},
          {key: 'this_week', value: 'This week'}
        ];
        $scope.refreshSuccess = false;
        $scope.refreshFail = false;
        $scope.refreshPending = false;

        $scope.refreshReports = function () {
          var url = apiPrefixUrl + '/appnexus/report_revenue/refresh';
          $scope.refreshSuccess = false;
          $scope.refreshFail = false;
          $scope.refreshPending = true;

          var range = time.getDateRange($scope.refreshDateRangeId);
          var setRefreshInterval = function () {
            $timeout(function () {
              $scope.refreshSuccess = false;
              $scope.refreshFail = false;
            }, 5000);
          };

          $http
            .post(url, {
              from_date: range ? time.dateToString(range.min) : null
            })
            .then(
              function () {
                $scope.refreshSuccess = true;
                $scope.refreshPending = false;
                setRefreshInterval();
              },
              function () {
                $scope.refreshFail = true;
                $scope.refreshPending = false;
                setRefreshInterval();
              }
            );

        }
}

