/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('SegmentsCtrl', ['$scope', '$filter', '$http', 'apiPrefixUrl', 'userServ', function($scope, $filter, $http, apiPrefixUrl, userServ) {

            var orderBy = $filter('orderBy');
            $scope.lastSortedBy = '';
            $scope.isSortedReverse = true;
            $scope.userServ = userServ;
            $scope.getExportToExcelURL = function(){
                return apiPrefixUrl + '/analytics/segments_stats/export_to_excel'
            };
            $scope.loading = true;

            $scope.sortSegments = function(category) {
                if($scope.lastSortedBy === category) {
                    $scope.isSortedReverse = !$scope.isSortedReverse;
                }
                else {
                    if(category === 'segment.name') {
                        $scope.isSortedReverse = false;
                    }
                    else {
                        $scope.isSortedReverse = true;
                    }
                }
                $scope.segmentsList = orderBy($scope.segmentsList, category, $scope.isSortedReverse);
                $scope.lastSortedBy = category;
            };

            $scope.getClassToSegmentLabel = function(column_name) {
                if ($scope.lastSortedBy !== column_name) {
                    return {sortable: true};
                }

                return {
                    sortable: true,
                    asc: !$scope.isSortedReverse,
                    desc: $scope.isSortedReverse,
                };
            };

            $http.get(apiPrefixUrl + '/analytics/segments_stats').then(function(resp) {
                var data = resp.data;
                $scope.loading = false;
                $scope.segmentsList = data;
                $scope.segmentsTotal = {
                    'uniques_total': 0,
                    'uniques_from_last_7days': 0,
                    'uniques_from_yesterday': 0,
                    'appnexus_monthly_uniques': 0,
                    'eu_visits_from_yesterday': 0
                };
                var segmentsNumer = $scope.segmentsList.length;
                for(var i = 0; i < segmentsNumer; i++) {
                    $scope.segmentsList[i].showSegment = true;
                    $scope.segmentsList[i].showSites = false;
                    if($scope.segmentsList[i].websites) {
                        for(var j = 0; j < $scope.segmentsList[i].websites.length; j++) {
                            $scope.segmentsList[i].websites[j].showWebsite = true;
                        }
                    }
                    $scope.segmentsTotal.uniques_total += $scope.segmentsList[i].uniques_total;
                    $scope.segmentsTotal.uniques_from_last_7days += $scope.segmentsList[i].uniques_from_last_7days;
                    $scope.segmentsTotal.uniques_from_yesterday += $scope.segmentsList[i].uniques_from_yesterday;
                    $scope.segmentsTotal.eu_visits_from_yesterday += $scope.segmentsList[i].eu_visits_from_yesterday;
                }
                $scope.sortSegments('uniques_from_yesterday');
            });

            $scope.searchSiteString = '';
            $scope.searchSiteStringConfirmed = '';
            $scope.searchSite = function() {
                if($scope.searchSiteString) {
                    $scope.searchSiteStringConfirmed = angular.copy($scope.searchSiteString);
                    for(var i = 0; i < $scope.segmentsList.length; i++) {
                        $scope.segmentsList[i].showSegment = false;
                        $scope.segmentsList[i].showSites = false;
                        if($scope.segmentsList[i].websites) {
                            for(var j = 0; j < $scope.segmentsList[i].websites.length; j++) {
                                if($scope.segmentsList[i].websites[j].domain.toLowerCase().indexOf($scope.searchSiteStringConfirmed.toLowerCase()) > -1) {
                                    $scope.segmentsList[i].websites[j].showWebsite = true;
                                    if(!$scope.segmentsList[i].showSegment) {
                                        $scope.segmentsList[i].showSegment = true;
                                        $scope.segmentsList[i].showSites = true;
                                    }
                                } else {
                                    $scope.segmentsList[i].websites[j].showWebsite = false;
                                }
                            }
                        }
                    }
                }
            };
            $scope.clearSearch = function() {
                $scope.searchSiteString = '';
                $scope.searchSiteStringConfirmed = '';
                for(var i = 0; i < $scope.segmentsList.length; i++) {
                    $scope.segmentsList[i].showSegment = true;
                    $scope.segmentsList[i].showSites = false;
                    if($scope.segmentsList[i].websites) {
                        for(var j = 0; j < $scope.segmentsList[i].websites.length; j++) {
                            $scope.segmentsList[i].websites[j].showWebsite = true;
                        }
                    }
                }
            };
    }]);

