/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .directive('adminCampaignsCampaignRow', function () {
    // because component destroys a table, I must use a directive
    return {
      restrict: 'A',
      controller: adminCampaignsCampaignRowCtrl,
      controllerAs: 'vm',
      template: require('$views/admin/campaigns/campaign-row.html').default,
      scope: {
        campaign: '=',
        lineItem: '=',
        campaignFields: '=',
        campaignsLength: '=',
        showLineItem: '=',
        campaignTypes: '<',
        revenueTypes: '<',
        campaignCategories: '<',
        postApChanged: '&'
      }
    };
  });

adminCampaignsCampaignRowCtrl.$inject = ['$scope', 'utils', '$filter'];

function adminCampaignsCampaignRowCtrl ($scope, utils, $filter) {
  var vm = $scope;
  var percentage = $filter('percentage');
  var currency = $filter('currency');
  var haveValue = utils.haveValue;

  vm.WEEKDAY_MAPPER = [
    'sunday', 'monday', 'tuesday', 'wednesday',
    'thursday', 'friday', 'saturday'
  ];

  vm.fieldIds = {};
  vm.campaignFields.forEach(function (field, index) {
    vm.fieldIds[field.field] = index;
  });

  vm.show = function (value) {
    return haveValue(value) ? value : 'None';
  };

  vm.showPercentage = function (value) {
    return haveValue(value) ? percentage(value) : 'None';
  };

  vm.showCurrency = function (value) {
    return haveValue(value) ? currency(value) : 'None';
  };

  vm.isInputDisabled = function (campaign) {
    return vm.lineItem.post_ap === false || (campaign && campaign.is_archived);
  };

  vm.isCampBudgetInputDisabled = function () {
    var line = vm.lineItem;
    var inputDisabled = vm.isInputDisabled(vm.campaign);
    if (line.source_data_type === 1) { // ALI: ADHES-2222
      return (
        inputDisabled ||
        vm.campaign.state === 'inactive' // ADHES-2228
      );
    }
    var daily_budget = getDailyBudget();
    return (
      inputDisabled ||
      !angular.isNumber(daily_budget) ||
      !angular.isNumber(line.revenue_value)
    );
  };

  vm.isPositiveNumericString = function (value) {
    value = parseFloat(value);
    return isFinite(value) && value >= 0.01;
  };

  vm.getDailyBudgetPerDay = function(daily_budget_per_day) {
    var weekday = new Date().getUTCDay();
    var attr = vm.WEEKDAY_MAPPER[weekday];
    return daily_budget_per_day[attr];
  };

  vm.onChangeImpCap = function () {
    if (vm.lineItem.source_data_type === 1) {  // ALI: ADHES-2222
      return;
    }
    var campaign = vm.campaign;
    var lineItem = vm.lineItem;
    var daily_budget = getDailyBudget(lineItem);
    var imp_cap = parseFloat(campaign.daily_budget_imps);
    if (isNaN(imp_cap)) {
      campaign.daily_budget_percent = null;
      return;
    }
    if (!angular.isNumber(lineItem.revenue_value)
        || !angular.isNumber(daily_budget)) {
      campaign.daily_budget_percent = null;
      return;
    }
    var line_imp_cap = getLineImpCap(lineItem);
    var ratio = imp_cap / line_imp_cap;
    campaign.daily_budget_percent = (ratio * 100).toFixed();
  };

  vm.onChangeDailyBudgetPercent = function (campaign, options) {
    if (vm.lineItem.source_data_type === 1) {  // ALI: ADHES-2222
      return;
    }

    options = options || {};
    var percent = parseFloat(campaign.daily_budget_percent);
    if (isNaN(percent)) {
      if (!options.dont_remove_invalid_imps) {
        campaign.daily_budget_imps = null;
      }
      return;
    }
    var line_imp_cap = getLineImpCap();
    var imp_cap = line_imp_cap * (percent / 100.0);
    // set 0 to null (unlimited in appnexus)
    campaign.daily_budget_imps = Math.round(imp_cap) || null;
  };

  vm.onChangeLineItemDailyBudget = function () {
    if (vm.lineItem.source_data_type === 1) {  // ALI: ADHES-2222
      return;
    }
    vm.lineItem.campaigns.forEach(function (campaign) {
      var update = $scope.onChangeDailyBudgetPercent;
      update(campaign, {dont_remove_invalid_imps: true});
    });
  };

  vm.onChangeRevenueValue = vm.onChangeLineItemDailyBudget;

  function getDailyBudget() {
    var budgets = vm.lineItem.daily_budget_per_day;
    if (!budgets || !budgets.active) {
      return vm.lineItem.daily_budget;
    }
    return vm.getDailyBudgetPerDay(vm.lineItem.daily_budget_per_day);
  }

  function getLineImpCap() {
    var daily_budget = getDailyBudget();
    return 1000.0 * daily_budget / vm.lineItem.revenue_value;
  }
}

