/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .controller('ForecastingCtrl', forecastingCtrl);

forecastingCtrl.$inject = ['apiPrefixUrl', 'userServ'];
function forecastingCtrl(apiPrefixUrl, userServ) {
  var vm = this;

  vm.selectedSearch = '';
  vm.search = '';
  vm.isLoading = false;
  vm.userServ = userServ;

  vm.filterForecasting = function () {
    vm.selectedSearch = vm.search;
  };

  vm.clearSearch = function() {
    vm.selectedSearch = '';
    vm.search = '';
  };

  vm.getExportToExcelURL = function () {
    var url = apiPrefixUrl + '/forecasting/excel';
    if (vm.selectedSearch) {
      url += '?name=' + vm.selectedSearch;
    }
    return url;
  };

  vm.columns = [
    {field: 'name', title: 'Rep', sortable: 'name', show: true},
    {field: 'advertiser_username', sortable: 'advertiser_username', title: 'Advertiser', show: true},
    {field: 'spend_mtdy', title: 'Spend MTD Yesterday', sortable:'spend_mtdy', show: true},
    {field: 'remaining_budget', title: 'Remaining Budget', sortable: 'remaining_budget', show: true},
    {field: 'spend_pacing_per_day', title: 'Spend Pacing/Day Remaining', sortable: 'spend_pacing_per_day', show: true},
    {field: 'spend_yesterday', title: 'Spend Yesterday', sortable: 'spend_yesterday', show: true},
    {field: 'monthly_budget', title: 'Monthly Budget', sortable: 'monthly_budget', show: true},
    {field: 'to_complete', title: '% to complete', sortable: 'to_complete', show: true},
    {field: 'daily_spend_yesterday_diff', title: 'Daily Spend Difference', sortable: 'daily_spend_yesterday_diff', show: true}
  ];
}


