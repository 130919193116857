/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .controller('RevenueCtrl', RevenueAdminCtrl);

RevenueAdminCtrl.$inject = [
  '$scope', '$filter', 'userServ', 'time', 'BillingService', 'AdvertiserService', 'ExcelService',
];

function RevenueAdminCtrl (
    $scope, $filter, userServ, time, BillingService, AdvertiserService, ExcelService) {
        var orderBy = $filter('orderBy');
        $scope.lastSortedBy = 'name';
        $scope.isSortedReverse = true;
        $scope.userServ = userServ;
        $scope.loading = true;
        $scope.summary = null;
        $scope.dateRangeId = 'today';
        $scope.additonalDateRangeIds = [
          {key: 'today', value: 'Today'}
        ];
        var merge = $scope.merge;
        $scope.merge = {
            state: {
              billings: false,
              advertisers: false,
              result: false,
            },
            rawData: {
              billings: null,
              advertisers: null,
              mergeData: null,
              result: null,
              total: null,
            },
            reset: function() {
              $scope.revenueList = [];
              var self = $scope.merge;
              self.state.billings = false;
              self.state.advertisers = false;
              self.state.result = false;
              self.rawData.billings = null;
              self.rawData.advertisers = null;
              self.mergeData = null;
              self.rawData.result = null;
              self.rawData.total = null;
            },
            isReady: function() {
              var state = $scope.merge.state;
              return state.billings && state.advertisers && state.result;
            },
            setBillings: function(billings) {
              var self = $scope.merge;
              console.log("Billings [done]", billings);
              self.state.billings = true;
              self.rawData.billings = billings;
              self.parseRawData();
            },
            setAdvertisers: function(advertisers) {
              var self = $scope.merge;
              console.log("Advertisers [done]", advertisers);
              self.state.advertisers = true;
              self.rawData.advertisers = advertisers;
              self.parseRawData();
            },
            parseRawData: function() {
              var self = $scope.merge;
              if(self.state.billings && self.state.advertisers) {
                self.mergeData = {};

                angular.forEach(self.rawData.advertisers, function(advertiser) {
                  var salesperson = advertiser.salesperson || "(unknown)",
                    advertiserId = advertiser.id.replaceAll("-", ""),
                    businessRelationship = advertiser.business_relationship || "direct";

                  self.mergeData[advertiserId] = {
                    name: advertiser.name,
                    businessRelationship: businessRelationship,
                    salesperson: salesperson,
                    revenue_agency: 0,
                    revenue_direct: 0,
                    revenue_value: 0,
                  };
                 });

                angular.forEach(self.rawData.billings, function(billling) {
                  var advertiserId = billling.advertiser_id,
                    revenueValue = billling.calculated_fee ?  parseFloat(billling.calculated_fee) : 0;
                  if(self.mergeData[advertiserId] === undefined) {
                    console.log("skip", advertiserId);
                    return;
                  }

                  var businessRelationship = self.mergeData[advertiserId].businessRelationship;
                  var row = self.mergeData[advertiserId];

                  row["revenue_value"] += revenueValue;
                  row["revenue_" + businessRelationship] += revenueValue;
                });

                self.rawData.result = {};
                self.rawData.total = {
                  "revenue_agency": 0,
                  "revenue_direct": 0,
                  "revenue_value": 0,
                }

                angular.forEach(self.mergeData, function(row) {
                  var result = self.rawData.result,
                    salesperson = row.salesperson,
                    total = self.rawData.total,
                    value = row.revenue_value;

                  if(value == 0) {
                    // don't show empty values
                    return;
                  }

                  if(result[salesperson] === undefined) {
                    result[salesperson] = {
                      name: salesperson,
                      advertisers: [],
                      showAdvertisers: false,
                      revenue_agency: 0,
                      revenue_direct: 0,
                      revenue_value: 0,
                    };
                  }
                  result[salesperson].advertisers.push({
                    name: row.name,
                    revenue_agency: row.revenue_agency,
                    revenue_direct: row.revenue_direct,
                    revenue_value: row.revenue_value,
                  });

                  result[salesperson].revenue_agency += row.revenue_agency;
                  result[salesperson].revenue_direct += row.revenue_direct;
                  result[salesperson].revenue_value += row.revenue_value;

                  total.revenue_agency += row.revenue_agency;
                  total.revenue_direct += row.revenue_direct;
                  total.revenue_value += row.revenue_value;
                });

                $scope.sortRevenue($scope.lastSortedBy);

                self.state.result = true;
                console.log("Merging [done]");
                $scope.$apply();
              }
            }
        };

        $scope.sortRevenue = function(category) {
            if($scope.lastSortedBy === category) {
              $scope.isSortedReverse = !$scope.isSortedReverse;
            } else {
              $scope.isSortedReverse = (category !== 'name');
            }
            $scope.revenueList = orderBy(Object.values($scope.merge.rawData.result), category, $scope.isSortedReverse);
            angular.forEach($scope.revenueList, function(salesperson) {
              salesperson.advertisers = orderBy(Object.values(salesperson.advertisers), category, $scope.isSortedReverse);
            });
            $scope.lastSortedBy = category;
        };

        $scope.getTableData = function(clear_picker, startDate, endDate) {
            if (clear_picker) {
              $scope.dateRangeId = '';
            }
            $scope.startDate = startDate;
            $scope.endDate = endDate;
            var params = {
              from_date: time.getBackendDate($scope.startDate),
              to_date: time.getBackendDate($scope.endDate)
            };

            $scope.merge.reset();
            BillingService.fetchBilling(params).then($scope.merge.setBillings);
            AdvertiserService.fetchAdvertisers().then($scope.merge.setAdvertisers);
        };


        $scope.chartData = {};
        $scope.chartData.options = {
            yaxis : {
                show: true,
                tickFormatter: function (v) {
                    return $filter('currency')(v);
                }
            },
            xaxis : {
                show:  true,
                autoscaleMargin: 0.05
            },
            bars: {
                align: 'center',
                barWidth: 0.5
            },
            grid : {
                show : true,
                margin: {
                    top: 20,
                    left: 10,
                    bottom: 0,
                    right: 30
                },
                borderWidth: 0,
                labelMargin: 10,
                color: '#000000',
                hoverable: true
            },
            series: {
                bars: {
                    show: true
                }
            },
            color: '#ffc200',
            shadowSize: 0,
            tooltip: {
                show: true,
                cssClass: 'flot-tooltip-campaigns'
            }
        };
        $scope.getModalData = function(data) {
            $scope.chartData.data = [{}];
            $scope.chartData.data[0].data = [];
            $scope.chartData.options.xaxis.ticks = [];
            $scope.chartData.data[0].label = 'Revenue Report';
            for(var i = 0; i < data.length; i++) {
                $scope.chartData.data[0].data.push([i, data[i].revenue_value]);
                $scope.chartData.options.xaxis.ticks.push([i, data[i].name]);
            }
            if(data.length === 1) {
                $scope.chartData.options.xaxis.autoscaleMargin = 0.5;
            } else {
                $scope.chartData.options.xaxis.autoscaleMargin = 0.05;
            }
            $scope.displayModal = true;
        };

        $scope.onExportToExcel = function() {
          ExcelService.downloadRevenueReport(
              time.getBackendDate($scope.startDate),
              time.getBackendDate($scope.endDate)).then(
            function() {},
            function(error) {
              // TODO: show error
              console.log(error);
            }
          );
        };
};

