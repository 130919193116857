/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('AppnexusSegmentsCtrl', ['$scope',
    function ($scope) {
      $scope.interval = 'today';
      $scope.intervalOptions = [
        {id: 'today', label: 'Today'},
        {id: 'yesterday', label: 'Yesterday'},
        {id: 'last_7_days', label: 'Last 7 Days'},
        {id: 'month_to_date', label: 'Month to Date'}
      ];
    }]);

