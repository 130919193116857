/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('accountHealthReport', {
    controller: accountHealthReportCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/account-health-report/main.html').default
  });


accountHealthReportCtrl.$inject = ['$location'];
function accountHealthReportCtrl($location) {
  var vm = this;

  vm.sort = '-advertiser_username';
  vm.updateSort = function (sort) {
    vm.sort = sort;
    vm.resetPage();
  };

  vm.searchNewValue = $location.search().search || localStorage.accountHealthSearch || '';
  vm.searchValue = vm.searchNewValue;
  vm.filterPricingModel = $location.search().pricing_model || localStorage.accountHealthPricingModel || '';
  setQuerySearch();

  vm.search = function (value) {
    value = (value || '').trim();
    vm.searchNewValue = value;
    vm.searchValue = value;
    setQuerySearch();
    localStorage.setItem('accountHealthSearch', value);
    vm.resetPage();
  };

  vm.setPricingModel = function () {
    setQuerySearch();
    localStorage.setItem('accountHealthPricingModel', vm.filterPricingModel);
  };

  function setQuerySearch () {
    $location.search({
      pricing_model: vm.filterPricingModel || undefined,
      search: vm.searchValue || undefined
    });
  }

  vm.clearSearch = function() {
    vm.search('');
  };

  vm.page = 1;
  vm.changePage = function (page) {
    vm.page = page;
  };

  vm.pagesCount = 0;
  vm.changePagesCount = function (pagesCount) {
    vm.pagesCount = pagesCount;
  };

  vm.resetPage = function() {
    vm.page = 1;
    vm.pagesCount = 1;
  };

}

