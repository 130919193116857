/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('accountHealthReportTable', {
    controller: accountHealthReportTableCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/account-health-report/table.html').default,
    bindings: {
      filterSearch: '<',
      filterPricingModel: '<',
      page: '<',
      onChangePagesCount: '&',
      onChangeSort: '&'
    }
  });


accountHealthReportTableCtrl.$inject = ['apiPrefixUrl', '$http', '$q', 'userServ'];
function accountHealthReportTableCtrl(apiPrefixUrl, $http, $q, userServ) {
  var vm = this;

  vm.isAdmin = userServ.isAdmin();

  vm.sort = 'advertiser_username';
  vm.canceler = null;
  vm.isLoading = false;

  vm.$onInit = function () {
    clearData();
  };

  vm.$onChanges = function () {
    loadData();
  };

  vm.changeSort = function (value) {
    if (vm.sort === '-' + value) {
      vm.sort = value;
    } else {
      vm.sort = '-' + value;
    }
    vm.onChangeSort({sort: vm.sort});
    if (vm.page === 1) {
      loadData();
    }
  };

  vm.columns = [
    {field: 'salesperson', title: 'Sales Rep', overmenu: 2, rowspan: 2,
      sortable: 'salesperson', show: true},
    {field: 'advertiser_username', title: 'Advertiser', overmenu: true, rowspan: 2,
      sortable: 'advertiser_username', show: true},
    {title: 'CPA/CPM', field: 'advertiser_pricing_model', overmenu: true, rowspan: 2, show: true,
      sortable: 'advertiser_pricing_model'},
    {title: 'CPA', show: false, overmenu: true, colspan: 3},
    {field: 'advertiser_cpa_goal', title: 'Goal', show: true, sortable: 'advertiser_cpa_goal'},
    {field: 'cpa_yesterday', title: 'Yest.', show: true, sortable: 'cpa_yesterday'},
    {field: 'cpa_7days', title: '7 days', show: true, sortable: 'cpa_7days'},
    {title: 'ROAS', show: false, overmenu: true, colspan: 3},
    {field: 'roas_goal', title: 'Goal', show: true, sortable: 'roas_goal'},
    {field: 'roas_yesterday', title: 'Yest.', show: true, sortable: 'roas_yesterday'},
    {field: 'roas_7days', title: '7 days', show: true, sortable: 'roas_7days'},
    {title: 'Tags Check', show: false, overmenu: true, colspan: 2},
    {field: 'visitor_tags_check', title: 'Visits from Last Hour', show: true},
    {field: 'conversion_tags_check', title: 'Conversions from Last 24H', show: true}
  ];

  function clearData () {
    vm.data = null;
    vm.errors = null;
  }

  function loadData () {
    clearData();
    vm.isLoading = true;
    if (vm.canceler) {
      vm.canceler.resolve();
    }
    vm.canceler = $q.defer();

    var query = {
      ordering: vm.sort,
      page: vm.page
    };

    if (vm.filterSearch) {
      query.search = vm.filterSearch;
    }

    if (vm.filterPricingModel) {
      query.pricing_model = vm.filterPricingModel;
    }

    vm.request = $http
      .get(apiPrefixUrl + '/account-health-monitoring/health-report', {
        params: query,
        timeout: vm.canceler.promise
      })
      .then(function (resp) {
        vm.isLoading = false;
        vm.onChangePagesCount({pagesCount: Math.ceil(resp.data.count / 20)});
        vm.data = resp.data.results || [];
        modifyData(vm.data);
      }, function (resp) {
        if (resp.status < 0) {
          return;
        }
        vm.isLoading = false;
        vm.errors = ['Report could not be loaded'];
        vm.onChangePagesCount({pagesCount: 0});
        vm.data = null;
      });
  }

  function modifyData (data) {
    data.forEach(function (obj) {
      obj.uri_advertiser_username = encodeURIComponent(obj.advertiser_username);
      obj.state = getStateRow(obj);
    });
  }

  function getStateRow(obj) {
    var counter = obj.conversion_tags_check + obj.visitor_tags_check;
    switch (counter) {
      case 0: return 'danger';
      case 1: return 'warning';
      case 2: return '';
    }
  }
}


