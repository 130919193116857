/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('adminCampaignsHeadTable', {
    controller: adminCampaignsHeadTableCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/campaigns/head-table.html').default,
    bindings: {
      advertiserProfile: '<',
      billingProfile: '<',
      authorizenetProfile: '<'
    }
  });

adminCampaignsHeadTableCtrl.$inject = ['utils', '$filter'];

function adminCampaignsHeadTableCtrl (utils, $filter) {
  var vm = this;
  var percentage = $filter('percentage');
  var currency = $filter('currency');
  var haveValue = utils.haveValue;

  vm.userFields = [
    'Status', 'Line Items', 'CPA OFF (7 day)',
    'COG (7 day)', '7 day spend', '7 day ROAS',
    'CPA OFF yest.', 'COG yest.', 'Spend yest.', 'Spend MTD yest.',
    'Imp cap', 'Imps Yest.', 'Credit Limit', 'Balance owed',
    'Funds', 'Charge status'
  ];

  vm.show = function (value) {
    return haveValue(value) ? value : 'None';
  };

  vm.showPercentage = function (value) {
    return haveValue(value) ? percentage(value) : 'None';
  };

  vm.showCurrency = function (value) {
    return haveValue(value) ? currency(value) : 'None';
  };

}

