/*** IMPORTS FROM imports-loader ***/


'use strict';
angular
  .module('adhesiveApp')
  .component('tagsMonitoring', {
    controller: tagsMonitoringCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/tags-monitoring/main.html').default,
  });


tagsMonitoringCtrl.$inject = ['apiPrefixUrl', 'time', '$location'];
function tagsMonitoringCtrl(apiPrefixUrl, time, $location) {
  var vm = this;

  vm.sort = '-website__name';
  vm.updateSort = function (sort) {
    vm.sort = sort;
    vm.resetPage();
  };

  vm.searchNewValue = $location.search().search || localStorage.tagsMonitoringSearch || '';
  vm.searchValue = vm.searchNewValue;
  setQuerySearch();

  vm.search = function (value) {
    value = (value || '').trim();
    vm.searchNewValue = value;
    vm.searchValue = value;
    setQuerySearch();
    localStorage.setItem('tagsMonitoringSearch', value);
    vm.resetPage();
  };

  function setQuerySearch () {
    $location.search({
      search: vm.searchValue || undefined
    });
  }

  vm.clearSearch = function() {
    vm.search('');
  };

  vm.page = 1;
  vm.changePage = function (page) {
    vm.page = page;
  };

  vm.pagesCount = 0;
  vm.changePagesCount = function (pagesCount) {
    vm.pagesCount = pagesCount;
  };

  vm.resetPage = function() {
    vm.page = 1;
    vm.pagesCount = 1;
  };

}

