/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component(
    'forecastingTable', {
      controller: forecastingTableCtrl,
      template: require('$views/admin/forecasting/forecasting-table.html').default,
      controllerAs: 'vm',
      bindings: {
        columns: '<',
        filterUsername: '<',
        isLoading: '=',
        summary: '='
      }
    }
  );

forecastingTableCtrl.$inject = ['$http', 'apiPrefixUrl', 'NgTableParams', 'ReportTable'];
function forecastingTableCtrl($http, apiPrefixUrl, NgTableParams, ReportTable) {
  var vm = this;
  vm.errors = [];
  vm.isLoading = false;
  vm.filterUsername = '';
  vm.tableParams = new NgTableParams(
    {
      sorting: {'name': 'asc'}
    },
    {
      getData: getForecasting,
      counts: []
    }
  );
  vm.reportTable = ReportTable(vm.tableParams);
  vm.setOrder = vm.reportTable.setOrder;
  vm.$onChanges = vm.reportTable.onChanges;

  function getForecasting(params) {
    vm.errors = [];
    vm.isLoading = true;
    var query = {};
    if (vm.filterUsername) {
      query.name = vm.filterUsername;
    }
    query = vm.reportTable.appendOrderingParam(params, query);

    return $http
      .get(apiPrefixUrl + '/forecasting/', {params: query})
      .then(function (resp) {
        var data = resp.data;
        vm.isLoading = false;
        if (data.records.length > 0) {
          vm.summary = data.summary;
        } else {
          vm.summary = null;
        }
        return data.records;
      }, function (resp) {
        vm.isLoading = false;
        vm.errors = ['Forecasting could not be loaded'];
        vm.summary = null;
        return [];
      });
  }
}

