/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('NewAdvertisersCtrl',
      ['$scope', '$http', 'time', 'apiPrefixUrl',
      function($scope, $http, time, apiPrefixUrl) {
        var params = {};
      $scope.loading = true;
    	$scope.newAdvertisersList = {};
    	$scope.pageNr = 1;
    	$scope.pageSize = 20;
    	$scope.fromDate = time.getUtcYesterdayDate();
    	$scope.toDate = time.getUtcNowDate();
    	$scope.numberOfPages = 1;
      $scope.partners = [{ key: '', name: 'Any' }];
      $scope.partner = '';
      $http.get(apiPrefixUrl + '/ra_partners').then(function(resp) {
        $scope.partners = $scope.partners.concat(resp.data);
      });
    	$scope.getData = function(setFirstPage) {
    		if($scope.pageSize > 100) {
    			$scope.pageSize = 100;
    		} else if($scope.pageSize < 1) {
    			$scope.pageSize = 1;
    		}
            if(setFirstPage) {
                $scope.pageNr = 1;
            }
            var params = {
    		      page: $scope.pageNr,
              page_size: $scope.pageSize,
              from_date: time.dateToString($scope.fromDate),
              to_date: time.dateToString($scope.toDate)
            };
            if($scope.partner !== '') {
                params.rap = $scope.partner;
            }
            $http
              .get(apiPrefixUrl + '/new_accounts', {params: params})
              .then(function(resp) {
                var data = resp.data;
                $scope.newAdvertisersList = data.results;
                $scope.numberOfPages = Math.ceil(data.count / $scope.pageSize);
                $scope.loading = false;
            });
    	};
    	$scope.getData();
    	$scope.changePage = function(page) {
    		if(page > $scope.numberOfPages) {
    			$scope.pageNr = $scope.numberOfPages;
    		} else if(page < 1) {
    			$scope.pageNr = 1;
    		} else {
    			$scope.pageNr = page;
    		}
    		$scope.getData();
    	};
    	$scope.getDataIfValid = function() {
    		if($scope.setdateform.$valid) {
    			$scope.getData(true);
    		}
    	};

    	//modal

    	$scope.displayModal = false;
    	$scope.chartFromDate = new Date(time.getNowDate().getTime() - 1000 * 60 *60 * 24 * 30);
    	$scope.chartToDate = time.getNowDate();
    	$scope.chartAccId = null;
    	$scope.chartEmail = '';
    	$scope.chartData = {};
    	$scope.chartData.options = {
            yaxis : {
                show: true
            },
            xaxis : {
                show:  true,
                mode: 'time',
                timeformat: '%b %d',
                ticks: 12,
                minTickSize: [1, 'day']
            },
            grid : {
                show : true,
                margin: {
                    top: 20,
                    left: 10,
                    bottom: 0,
                    right: 30
                },
                borderWidth: 0,
                labelMargin: 25,
                color: '#000000',
                hoverable: true
            },
            series: {
                lines: {
                    show: true
                },
                points: {
                    show: true
                }
            },
            color: '#ffc200',
            shadowSize: 0,
            tooltip: {
                show: true,
                cssClass: 'flot-tooltip-campaigns'
            },
            legend: {
            	show: true,
            	position: 'ne'
            }
        };
    	$scope.getModalData = function(accId, mail) {
    		$scope.chartAccId = accId;
    		if(mail) {
    			$scope.chartEmail = mail;
    		}
    		var params = {
    		  params: {
    		    from_date: time.dateToString($scope.chartFromDate),
            to_date: time.dateToString($scope.chartToDate)
    		  }};
            $http
              .get(apiPrefixUrl + '/analytics/uniques_by_day/' + accId, params)
              .then(function(resp) {
                var data = resp.data;
                $scope.chartData.data = [];
                for(var i = 0; i < data.websites.length; i++) {
                	$scope.chartData.data[i] = {};
                	$scope.chartData.data[i].label = ' ' + data.websites[i].name;
                	$scope.chartData.data[i].data = [];
                	for(var j = 0; j < data.websites[i].metrics.length; j++) {
                    	$scope.chartData.data[i].data.push([new Date(data.websites[i].metrics[j].day).getTime(), data.websites[i].metrics[j].uniques]);
                	}
                }
                $scope.displayModal = true;
            });
    	};
    	$scope.getChartDataIfValid = function() {
    		if($scope.setdatechart.$valid) {
    			$scope.getModalData($scope.chartAccId);
    		}
    	};
    }]);

