/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .component('creativeReports', {
    controller: creativeReportsCtrl,
    controllerAs: 'vm',
    template: require('$views/admin/creative-reports/main.html').default
  });


creativeReportsCtrl.$inject = ['apiPrefixUrl', 'time', 'userServ'];
function creativeReportsCtrl(apiPrefixUrl, time, userServ) {
  var vm = this;

  vm.userServ = userServ;

  vm.isLoading = false;
  vm.sort = '-revenue';
  vm.updateSort = function (sort) {
    vm.sort = sort;
    vm.resetPage();
  };

  vm.selectedHours = [];
  generateHours();

  vm.selectedAdvertiser = null;
  vm.filterAdvertiser = function (advertiser) {
    vm.selectedAdvertiser = advertiser;
    vm.resetPage();
  };

  vm.selectedCreative = '';
  vm.searchCreative = '';
  vm.filterCreative = function() {
    vm.selectedCreative = vm.searchCreative;
    vm.resetPage();
  };

  vm.page = 1;
  vm.changePage = function (page) {
    vm.page = page;
  };

  vm.pagesCount = 0;
  vm.changePagesCount = function (pagesCount) {
    vm.pagesCount = pagesCount;
  };

  vm.resetPage = function() {
    vm.page = 1;
    vm.pagesCount = 1;
  };

  vm.startDate = null;
  vm.endDate = null;
  vm.changeDatesFromCalendar = function (startDate, endDate) {
    vm.startDate = startDate;
    vm.endDate = endDate;
    vm.dateRangeId = null;
    vm.resetPage();
  };

  vm.dateRangeId = 'yesterday';
  vm.changeDatesFromSelectPicker = function (startDate, endDate) {
    vm.startDate = startDate;
    vm.endDate = endDate;
    vm.resetPage();
  };

  vm.groups = [
    {group: 'pixels', name: 'Pixel'},
    {group: 'hours', name: 'Hour'},
    {group: 'part_of_days', name: 'Part of day'},
    {group: 'countries', name: 'Countries'}
  ];
  vm.groupBy = 'pixels';

  vm.getExportToExcelURL = function () {
    var url = (
      apiPrefixUrl +
      '/creative-reports/excel' +
      '?from_date=' + time.getBackendDate(vm.startDate) +
      '&to_date=' + time.getBackendDate(vm.endDate) +
      '&ordering=' + encodeURIComponent(vm.sort)
    );
    if (vm.selectedAdvertiser) {
      url += '&advertiser=' + encodeURIComponent(vm.selectedAdvertiser);
    }
    if (vm.selectedCreative) {
      url += '&creative=' + encodeURIComponent(vm.selectedCreative);
    }
    if (vm.selectedHours && vm.selectedHours.length > 0) {
      url += '&hour=' + encodeURIComponent(vm.selectedHours.join(','));
    }
    return url;
  };

  function generateHours() {
    vm.hours = [];
    for (var hour = 0; hour < 24; hour++) {
      var hour12 = time.hourToHour12(hour);
      vm.hours.push({hour: hour, hour12: hour12});
    }
  }
}

