/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('CustomSegmentsCtrl', ['$scope', 'customSegments', '$uibModal', '$filter',
    function ($scope, customSegments, $modal, $filter) {
        var orderBy = $filter('orderBy');
      $scope.customSegments = [];
        $scope.loading = true;
        $scope.searchSiteString = '';
        $scope.searchSiteStringConfirmed = '';
        $scope.msg = [];

      customSegments.getCustomSegments().then(function(resp){
        $scope.customSegments = resp.data;
        $scope.loading = false;
        angular.forEach($scope.customSegments, function(segment) {
            segment.showSegment = true;
            segment.showDetails = false;
        });
      });

      $scope.deleteCustomSegment = function(id, index){
          var modalInstance = $modal.open({
             animation: $scope.animationsEnabled,
             template: require('$views/admin/modal_segment.html').default
          });
          modalInstance.result.then(function () {
            if(id){
              customSegments.deleteCustomSegment(id).then(function(resp){
            $scope.customSegments.splice(index, 1);
            $scope.msg = ["Segment deleted", 'alert-success'];
          }, function(resp){
            $scope.msg = ["Cannot delete", 'alert-danger'];
          });
            }
            else {
              $scope.msg = [];
              $scope.customSegments.splice(index, 1);
            }
          });
      };

      $scope.addCustomSegment = function(){
            $scope.msg = [];
        $scope.customSegments.unshift({
          "name": '',
          "keywords": [],
          'showSegment': true,
          'showDetails': true
        });
      };

      $scope.saveCustomSegment = function(id, index){
        var current = $scope.customSegments[index];
        if($scope.validateSegment(current)){
          if(!id){
            customSegments.addCustomSegment({name:current.name, keywords:current.keywords})
              .then(function(resp){
                $scope.msg = ["Segment added", 'alert-success'];
                $scope.customSegments[index] = resp.data;
                $scope.customSegments[index].showDetails = true;
                $scope.customSegments[index].showSegment = true;
            }, function(resp){
                $scope.msg = ["There were errors during adding this segment, please fix them", 'alert-danger'];
                current.nameErrors = resp.data.name || [];
                current.keywordErrors = resp.data.keywords || [];
            });
          }
          else {
            customSegments.updateCustomSegment(id, {name:current.name, keywords:current.keywords})
              .then(function(){
                $scope.msg = ["Segment updated", 'alert-success'];
            }, function(resp){
                $scope.msg = ["There were errors during saving this segment, please fix them", 'alert-danger'];
                current.nameErrors = resp.data.name || [];
                current.keywordErrors = resp.data.keywords || [];
            });
          }
        }
      };

      $scope.validateSegment = function(segment){
            segment.nameErrors = [];
            segment.keywordErrors = [];
            if(!segment.name){
                segment.nameErrors[0] = 'Name can\'t be blank';
            }
            if(segment.keywords.length <= 0){
                segment.keywordErrors[0] = 'Keywords can\'t be blank!';
            }
        return segment.name && segment.keywords.length > 0;
      };

        $scope.sortSegments = function(category) {
            if($scope.lastSortedBy === category) {
                $scope.isSortedReverse = !$scope.isSortedReverse;
            }
            else {
                if(category === 'name') {
                    $scope.isSortedReverse = false;
                }
                else {
                    $scope.isSortedReverse = true;
                }
            }
            $scope.customSegments = orderBy($scope.customSegments, category, $scope.isSortedReverse);
            $scope.lastSortedBy = category;
        };

        $scope.getClassToSegmentLabel = function(column_name) {
            if ($scope.lastSortedBy !== column_name) {
                return {sortable: true};
            }

            return {
                sortable: true,
                asc: !$scope.isSortedReverse,
                desc: $scope.isSortedReverse,
            };
        };

        $scope.searchSite = function() {
            if($scope.searchSiteString) {
                $scope.searchSiteStringConfirmed = angular.copy($scope.searchSiteString);
                angular.forEach($scope.customSegments, function(segment) {
                    segment.showSegment = segment.name.toLowerCase().indexOf($scope.searchSiteStringConfirmed.toLowerCase()) > -1;
                    segment.showDetails = false;
                });
            }
        };
        $scope.clearSearch = function() {
            $scope.searchSiteString = '';
            $scope.searchSiteStringConfirmed = '';
            for(var i = 0; i < $scope.customSegments.length; i++) {
                $scope.customSegments[i].showSegment = true;
                $scope.customSegments[i].showSites = false;
                if($scope.customSegments[i].websites) {
                    for(var j = 0; j < $scope.customSegments[i].websites.length; j++) {
                        $scope.customSegments[i].websites[j].showWebsite = true;
                    }
                }
            }
        };

    }]);

